import React, { useRef, useState } from 'react';
import imageCompression from 'browser-image-compression';
import 'react-image-crop/dist/ReactCrop.css';
import { centerCrop, makeAspectCrop } from 'react-image-crop';
import { ERROR_VARIANT } from '../constants/constant';
import showToast from '../utils/toast';

export default function useMediaPicker(imageInfo) {
    const MAX_FILE_SIZE = 300 * 1024; // 300KB in bytes
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const fileInputRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    // const [crop, setCrop] = useState({
    //     unit: 'px', // Use pixel as unit for dimensions
    //     width: 1080, // Minimum width for the crop box (in px)
    //     height: 608, // Aspect ratio 16:9 (height = 9/16 * width)
    //     x: 0,
    //     y: 0,
    // });

    // <--------- Compress Image Quality --------->
    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.2,               // Aim for an output under 200 KB for quick loading
            maxWidthOrHeight: imageInfo?.maxWidth || 1080,       // Target width/height for Instagram quality (1080px wide max)
            useWebWorker: true,           // Uses a Web Worker for better performance
            initialQuality: 0.85,         // Start with a high quality for best initial results
            alwaysKeepResolution: true,   // Ensure it keeps the resolution while reducing file size
        };


        try {
            let compressedFile = file;
            let imageSize = file.size;

            compressedFile = await imageCompression(file, options);
            imageSize = compressedFile.size;

            return compressedFile;
        } catch (error) {
            console.error("Error during image compression:", error);
            return file;
        }
    };

    // <--------- Handle Image Crop --------->
    const handleCrop = async () => {
        const croppedImageBlob = await getCroppedImg(imageUrl, crop);
        const compressedImage = await compressImage(croppedImageBlob);

        const image = new Image();
        image.src = URL.createObjectURL(compressedImage);

        const originalFileName = compressedImage?.name || "default-image-name.jpg";

        // Convert compressed image Blob to File object with determined name
        const imageFile = new File([compressedImage], originalFileName, {
            type: compressedImage.type,
            lastModified: Date.now()
        });

        image.onload = () => {
            const dimensions = {
                width: image.width,
                height: image.height
            };

            const imageFileData = {
                file: imageFile,
                filePath: URL.createObjectURL(compressedImage),
                type: compressedImage.type,
                name: compressedImage.name || "cropped-image.jpeg",
                size: (compressedImage.size / 1024).toFixed(2), // Size in KB
                dimension: dimensions
            };

            setImageFile(imageFileData);
            setShowCropper(false);
        }
    };

    // Function to get cropped image from canvas
    const getCroppedImg = (imageSrc, crop) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = imageSrc;

        return new Promise((resolve, reject) => {
            image.onload = () => {

                // Calculate the dimensions based on crop and image dimensions
                const scaleX = image.naturalWidth / 100;
                const scaleY = image.naturalHeight / 100;

                canvas.width = crop.width * scaleX; // Calculate the cropped width
                canvas.height = crop.height * scaleY; // Calculate the cropped height

                // Draw cropped image on canvas
                ctx.drawImage(
                    image,
                    crop.x * scaleX, // Adjust x based on scaling
                    crop.y * scaleY, // Adjust y based on scaling
                    crop.width * scaleX, // Crop width based on scaling
                    crop.height * scaleY, // Crop height based on scaling
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );

                canvas.toBlob((blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    resolve(blob);
                }, 'image/jpeg');
            };

            image.onerror = reject;
        });
    };


    // <--------- Media From Gallery --------->
    const onGallery = async (event) => {
        const file = event.target.files[0]; // Get the selected file from input

        if (!file) return;

        // Create an Image object to check the width
        const image = new Image();
        const imageURL = URL.createObjectURL(file);
        image.src = imageURL;

        image.onload = () => {
            const width = image.width;

            // Check if width is less than image info width
            if (width < (imageInfo?.maxWidth || 1080)) {
                showToast(`Image width is less than ${imageInfo?.maxWidth}px`, ERROR_VARIANT)
                URL.revokeObjectURL(imageURL);
                return;
            }

            // If width is 1080 or more, proceed to read the file as Data URL
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () => {
                const imageUrl = reader.result?.toString() || "";
                setImageUrl(imageUrl);
            });

            // Show the cropper only if the width condition is met
            setShowCropper(true);

            // Clean up the object URL
            URL.revokeObjectURL(imageURL);
        };

        // Clear the file input field
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };


    // Handle image loading and setting crop for 4:3
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const croppedWidthInpercent = (800 / width) * 100;
        const crop = makeAspectCrop(
            {
                unit: '%',
                width: 100, // Width is 100% of the image
            },
            imageInfo?.ratio || 16 / 9,
            width,
            height
        );

        const centeredCrop = centerCrop(crop, width, height);
        setCrop({ ...centeredCrop, locked: false });
    };

    return {
        showCropper,
        setShowCropper,
        crop,
        setCrop,
        handleCrop,
        onGallery,
        imageFile,
        imageUrl,
        onImageLoad,
        fileInputRef
    };
}
