import React, { useEffect, useState } from 'react'
import { updateRoleApi } from '../../rest/RoleApi';
import showToast from '../../utils/toast';
import { ERROR_VARIANT } from '../../constants/constant';

export default function Permissions({ permissions = [], featureName, selectedRole, setSelectedRole }) {
    const [rolePermissions, setRolePermissions] = useState(permissions);

    useEffect(() => {
        setRolePermissions(permissions);
    }, [permissions])

    // Fetch roles data from the API
    const updateRole = async (newPermissions, updatedPermissions) => {
        const roleId = selectedRole?.id;
        if (!roleId) {
            showToast('No role selected.', ERROR_VARIANT);
            return;
        }

        const body = {};
        body.name = selectedRole?.name;
        body.description = selectedRole?.description;
        const filteredPermissions = selectedRole?.permissions?.filter((item) =>
            !item?.startsWith(`${featureName}.`)
        ) || [];
        body.permissions = [...filteredPermissions, ...newPermissions];

        const result = await updateRoleApi(roleId, body);
        if (result?.status === 200) {
            setSelectedRole({ ...selectedRole, permissions: body.permissions });
            setRolePermissions(updatedPermissions);
        }
    };

    async function handlePermissions(index) {
        const updatedPermissions = rolePermissions.map((permission, idx) =>
            idx === index
                ? { ...permission, isGranted: !permission.isGranted }
                : permission
        );
        const newPermissions = updatedPermissions
            .filter(item => item?.isGranted)
            .map(item => `${featureName}.${item?.permissionCode}`);

        await updateRole(newPermissions, updatedPermissions);
    }

    return (
        rolePermissions.map((permission, idx) => (
            <div key={idx} className='flex items-center'>
                <input
                    type='checkbox'
                    checked={permission.isGranted}
                    onChange={() => handlePermissions(idx)}
                    className={`h-4 w-4 mr-2 cursor-pointer checked:bg-sky-500 border-2 rounded-md border-gray-400 checked:border-sky-500`}
                />
                <div className='font-quicksand font-semibold text-xs mr-4'>
                    {permission.permissionName}
                </div>
            </div>
        ))
    )
}
