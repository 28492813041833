import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { factStatisticsOverviewList } from '../constants/dummyData';
import FactDataTable from '../components/dataTables/FactDataTable';

export default function FactPage() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true);
        }, 1000)
    }, [])
    return (
        <div className='mx-8'>
            <div className='grid grid-cols-4 gap-6'>
                {
                    isLoading ? (
                        factStatisticsOverviewList.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center h-28 bg-white rounded-xl'>
                                    <div className='flex items-center h-16 ml-8'>
                                        <div style={{ backgroundColor: item.iconBackgroundColor }} className={`flex justify-center items-center rounded-full h-14 w-14`}>
                                            <Icon
                                                icon={item.icon}
                                                color={item.iconColor}
                                                fontSize={26}
                                                strokeWidth={20}
                                            />
                                        </div>
                                        <div className='ml-6'>
                                            <div className='font-quicksand font-bold text-xl'>{item.statusNumber}</div>
                                            <div className='font-quicksand text-gray-400 font-bold text-sm'>{item.statusName}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        factStatisticsOverviewList.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center h-28 bg-white rounded-xl'>
                                    <div className='flex items-center h-16 ml-8'>
                                        <div className={`flex justify-center items-center bg-gray-100 rounded-full h-14 w-14`}>
                                        </div>
                                        <div className='ml-6'>
                                            <div className='h-4 w-14 bg-gray-100 rounded-lg'></div>
                                            <div className='h-4 w-28 bg-gray-100 rounded-lg mt-2'></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
            <FactDataTable />
        </div>
    )
}
