import React from 'react'
import { Icon } from '@iconify/react';
import { userStatisticsOverviewList } from '../constants/dummyData';
import UserDataTable from '../components/dataTables/UserDataTable';

export default function UserPage() {
    return (
        <div className='mx-8'>
            <div className='grid grid-cols-4 gap-6 '>
                {
                    userStatisticsOverviewList.map((item, index) => {
                        return (
                            <div key={index} className='flex items-center h-28 bg-white rounded-xl'>
                                <div className='flex items-center h-16 ml-8'>
                                    <div style={{ backgroundColor: item.iconBackgroundColor }} className={`flex justify-center items-center rounded-full h-14 w-14`}>
                                        <Icon
                                            icon={item.icon}
                                            color={item.iconColor}
                                            fontSize={24}
                                            strokeWidth={20}
                                        />
                                    </div>
                                    <div className='ml-6'>
                                        <div className='font-quicksand font-bold text-xl'>{item.statusNumber}</div>
                                        <div className='font-quicksand text-gray-400 font-bold text-sm'>{item.statusName}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <UserDataTable />
        </div>
    )
}
