import React from 'react'

export default function Loader({ size = '1.4rem', color = 'white', borderWidth = '3px' }) {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`aspect-square border-t-transparent border-solid rounded-full animate-spin-slow`}
        style={{
          height: size,
          borderTopColor: 'transparent',
          borderLeftColor: color,
          borderRightColor: color,
          borderBottomColor: color,
          borderWidth: borderWidth,
        }}
      >
      </div>
    </div>
  )
}