import React from 'react'

export default function ImageViewer({ imgUrl }) {
    return (
        <div className='p-6'>
            <img
                src={imgUrl}
                className="w-full rounded-xl z-[90]"
                alt="Selected"
            />
        </div>
    )
}
