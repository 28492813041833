import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Toaster } from 'react-hot-toast';
import RecoilNexus from 'recoil-nexus';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <RecoilNexus />
      <Toaster
        toastOptions={{
          duration: 3000,
          position: 'bottom-center',
          style: {
            fontFamily: 'Quicksand, sans-serif',
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: '#fff',
            borderRadius: '8px',
            padding: '10px',
          }
        }}
      />
      <App />
    </RecoilRoot>
  </BrowserRouter>
);
