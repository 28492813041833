import React, { useEffect } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'

export default function CustomModal({ setIsModalOpen, showCross = true, children, width = 'w-[500px]', childrenProps = {} }) {

  function handleClose() {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      className='fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50'>
      {/* <div
        onClick={handleClose}
        className='absolute top-8 right-8 bg-gray-50 rounded-full h-9 w-9 flex justify-center items-center cursor-pointer'>
        <Icon
          icon='charm:cross'
          color='black'
          fontSize={28}
        />
      </div> */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white relative rounded-xl max-h-[90vh] ${width}`}>
        {
          showCross && (
            <div
              onClick={handleClose}
              className='absolute -top-3 -right-3 z-40 bg-white shadow-md rounded-full h-8 w-8 flex justify-center items-center cursor-pointer'>
              <Icon
                icon='charm:cross'
                color='black'
                fontSize={24}
              />
            </div>
          )
        }
        <div className='max-h-[90vh] overflow-y-auto rounded-xl'>
          {
            React.isValidElement(children)
              ? React.cloneElement(children, { ...childrenProps })
              : children
          }
        </div>
      </div>
    </div>
  )
}
