import React, { useState } from 'react'
import { CustomButton, InputField, Label } from '../common/Form';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import { useForm } from 'react-hook-form';
import { userRegistrationApi } from '../../rest/AuthApi';
import usePasswordStrength from '../../hooks/usePasswordStrength';
import { useNavigate } from 'react-router-dom';

export default function SignupForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { register, watch, getValues, setError, clearErrors, handleSubmit, formState: { errors } } = useForm();
    const passwordStrength = usePasswordStrength(watch);

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        data.email = data.email.toLowerCase();
        setIsLoading(true)
        const result = await userRegistrationApi(data);
        if (result?.status === 201) {
            const param = { email: data.email, name: data.name };
            navigate('/Verifyotp', { state: param });
            showToast('Signup success', SUCCESS_VARIANT);
        }
        else if (result?.data?.code === "EMAIL_ALREADY_EXISTS") {
            showToast('Account Already Exists', ERROR_VARIANT);
        }
        setIsLoading(false);
    }

    function customSubmit() {
        clearErrors();
        const password = getValues('password');
        const confirmPassword = getValues('confirmPassword');

        if (!passwordStrength?.isAllowed && password) {
            setError(`password`, {
                type: "manual",
                message: "Looks like your password needs a little more muscle!"
            });
            return;
        }

        if (password !== confirmPassword) {
            setError(`confirmPassword`, {
                type: "manual",
                message: "Oops! Your passwords don’t match. Let’s make them twins!"
            });
            return;
        }

        handleSubmit(onSubmit)();
    }

    return (
        <form
            onSubmit={handleSubmit(customSubmit)}
            className='flex-col justify-center'>
            <div
                className='text-center font-quicksand font-bold text-black text-2xl mt-8'>
                Signup
            </div>

            <div className='mt-8'>
                <Label text='Name' />
                <InputField
                    register={register}
                    name={'name'}
                    placeholder={'Enter your name...'}
                    pattern={{
                        required: "Don’t leave me blank! Your name is a must."
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-4'>
                <Label text='Email' />
                <InputField
                    register={register}
                    name={'email'}
                    placeholder={'Enter your email address...'}
                    pattern={{
                        required: "Your email is the key—don’t leave it out!",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-4'>
                <div className='flex'>
                    <Label text='Password' />
                    {
                        passwordStrength && (
                            <span
                                style={{ color: passwordStrength?.color }}
                                className='font-quicksand text-sm font-semibold ml-3'>
                                {passwordStrength?.text}
                            </span>
                        )
                    }
                </div>
                <InputField
                    style=''
                    type='password'
                    register={register}
                    name={'password'}
                    secureTextEntry={true}
                    placeholder={'Create a secure password...'}
                    pattern={{
                        required: "Your password is required"
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-4'>
                <Label text='Confirm Password' />
                <InputField
                    style=''
                    type='password'
                    register={register}
                    name={'confirmPassword'}
                    secureTextEntry={true}
                    placeholder={'Re-enter your password...'}
                    pattern={{
                        required: "Let’s double-check that password!"
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Signup'
                    isLoading={isLoading}
                    height='2.5rem'
                />
            </div>


            <div className='mt-6'>
                <div
                    // style={{ fontSize: scaleFont(13) }}
                    className='text-center font-quicksand font-medium text-black text-sm'>
                    By registering you agree to our
                    <span
                        // onClick={() => navigation.navigate('ForgotPassword')}
                        className='text-custom-blue font-quicksand font-bold cursor-pointer'>
                        {` Terms of use `}
                    </span>
                    and
                    <span
                        // onClick={() => navigation.navigate('ForgotPassword')}
                        className='text-custom-blue font-quicksand font-bold cursor-pointer'>
                        {` Privacy Policy`}
                    </span>
                </div>
                <div
                    className='text-center font-quicksand font-medium text-black text-sm mt-4'>
                    Already have an account?
                    <span
                        onClick={() => navigate('/login')}
                        className='text-custom-blue font-quicksand font-bold cursor-pointer'>
                        {` Login`}
                    </span>
                </div>
            </div>
        </form>
    )
}
