import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import CustomModal from '../modals/CustomModal';
import Pagination from '../common/Pagination';
import AddNewRoleForm from '../forms/AddNewRoleForm';
import { getAllRolesApi } from '../../rest/RoleApi';
import { useRecoilState } from 'recoil';
import { customBlue } from '../../constants/color';
import NoDataFound from '../common/NoDataFound';
import Loader from '../common/Loader';

const GlobalFilter = ({ globalFilter, setGlobalFilter, setIsModalOpen, showAddNewButton }) => {
    return (
        <div className={`flex ${showAddNewButton ? 'justify-between' : 'justify-end'} px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg`}>
            <div className='flex items-center h-8'>
                <input
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                    className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                    placeholder="Search..."
                />
                <button
                    className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                >
                    <Icon
                        icon='mingcute:search-line'
                        color='white'
                        fontSize={20}
                    />
                </button>
            </div>
            {showAddNewButton && (
                <div className='h-8'>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    >
                        Add new role
                    </button>
                </div>
            )}
        </div>
    );
};

const columns = [
    {
        Header: 'Role',
        accessor: 'name',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
];

const RoleDataTable = ({ showAddNewButton = true, allFeatures = [], fetchRoleByName }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [roleName, setRoleName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageSize } = state;

    // Fetch roles data from the API
    const fetchData = async () => {
        setIsLoading(true);
        const result = await getAllRolesApi(pageNumber, pageSize);
        if (result?.status === 200) {
            setData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    const handleRowClick = (row) => {
        const selectedRoleName = row?.cells[0]?.value;
        fetchRoleByName(selectedRoleName);
        setRoleName(selectedRoleName);
    };

    return (
        <div className="p-8 bg-white rounded-xl">
            {isModalOpen && (
                <CustomModal
                    width='w-[28rem]'
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                >
                    <AddNewRoleForm
                        allFeatures={allFeatures}
                        setData={setData}
                    />
                </CustomModal>
            )}

            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                showAddNewButton={showAddNewButton}
            />

            <div className="overflow-x-auto mt-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                    >
                                        <div className='flex items-center'>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <Icon
                                                            icon='eva:arrow-down-fill'
                                                            color='gray'
                                                            fontSize={14}
                                                        />
                                                    ) : (
                                                        <Icon
                                                            icon='eva:arrow-up-fill'
                                                            color='gray'
                                                            fontSize={14}
                                                        />
                                                    )
                                                ) : (
                                                    <Icon
                                                        icon='flowbite:sort-outline'
                                                        color='gray'
                                                        fontSize={14}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            isLoading ? (
                                <tr>
                                    <td
                                        colSpan={columns.length} // Spans all columns
                                        className="px-4 py-6 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                    >
                                        <Loader
                                            color={customBlue}
                                            size="2rem"
                                            borderWidth='3px'
                                        />
                                    </td>
                                </tr>
                            ) :
                                page.length > 0 ? (
                                    page.map((row, rowIndex) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className={`cursor-pointer hover:bg-gray-200 ${roleName === row?.cells[0]?.value ? 'bg-gray-200' : ''}`}
                                                onClick={() => handleRowClick(row)}
                                            >
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300"
                                                    >
                                                        {cell.column.id === 'description' && !cell.value ? (
                                                            '...'
                                                        ) : (
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })) : (
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                            className='border-b border-gray-300'
                                        >
                                            <NoDataFound
                                                message={'No Role Found'}
                                            />
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-6">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default RoleDataTable;
