import React, { useState } from 'react'
import { CustomButton, InputField, Label } from '../common/Form'
import { useForm } from 'react-hook-form';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import { addNewFeatureApi } from '../../rest/FeatureApi';

export default function AddNewFeatureForm({ setAllFeatures }) {
    const [isLoading, setIsLoading] = useState()
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    async function onSubmit(data) {
        setIsLoading(true);
        const result = await addNewFeatureApi(data);
        if (result?.status === 201) {
            setAllFeatures((prevFeatures) => [...prevFeatures, data])
            showToast('Feature created', SUCCESS_VARIANT);
            reset();
        }
        else if (result?.status === 400 && result?.data?.code === 'UNKNOWN') {
            showToast('This feature already exists.', ERROR_VARIANT);
        }
        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex-1 p-6 bg-white">
            <div className="text-black text-center font-quicksand font-bold text-lg">
                Add new feature
            </div>

            <div className="flex-1 justify-between px-3 mt-6">

                <div className='mt-4'>
                    <Label text='Feature name' />
                    <InputField
                        style=''
                        register={register}
                        name={'name'}
                        secureTextEntry={true}
                        placeholder={'Enter feature name...'}
                        pattern={{
                            required: "Feature name is required"
                        }}
                        errors={errors}
                    />
                </div>

                <div className='mt-6'>
                    <CustomButton
                        height='2.4rem'
                        text='Add'
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </form>
    )
}
