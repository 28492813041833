import React from 'react';
import { useRecoilValue } from 'recoil';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticatedState } from '../recoil/store';
import { curveBackgroundDown, curveBackgroundUp } from '../constants/ImagesAndIcons';

export default function UnprotectedRoute() {
    const isLoggedIn = useRecoilValue(isAuthenticatedState);
    return (
        <div className='flex justify-center items-center h-[100vh] bg-white relative'>
            <div className='flex h-[90vh] w-[90vw] rounded-3xl drop-shadow-2xl overflow-hidden'>
                <div className='flex bg-white w-full'>
                    <div
                        // style={{
                        //     background: 'rgb(255,255,255)',
                        //     background: 'linear-gradient(153deg, rgba(255,255,255,1) 0%, rgba(108,162,255,1) 25%)'
                        // }}
                        className='w-[60%] flex justify-center items-center bg-custom-blue relative'>
                        {/* <img src={curveBackgroundUp} className='w-full absolute top-0' /> */}
                        <div>
                            {/* <img src='./assets/images/undraw.svg' className='w-[40%] h-[40%] rounded-2xl' /> */}
                            <div className='px-[20%]'>
                                <h2 className='text-7xl text-white font-quicksand font-bold'>
                                    Welcome to <br />Factaa!
                                </h2>
                                <h4 className='text text-white font-quicksand font-semibold mt-4'>
                                    Discover, learn, and share amazing facts from around the world. Get daily insights, keep track of your favorite facts, and even contribute your own! Dive in and start exploring.
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className='bg-white w-[40%] px-[7%] flex justify-center items-center'>
                        <div className='w-full'>
                            {/* <div className='text-center font-quicksand font-bold text-custom-blue text-3xl'>
                                All
                                <span className='text-custom-yellow'>
                                    Facts
                                </span>
                            </div> */}
                            <div className='text-center font-courgette font-bold text-black text-3xl'>
                                Factaa
                            </div>

                            {isLoggedIn ? <Navigate to='/' /> : <Outlet />}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}