import { atom } from "recoil";

export const isAuthenticatedState = atom({
    key: "Is_Authenticated",
    default: false
});

export const userState = atom({
    key: "User_Details",
    default: null
});

export const modalStackState = atom({
    key: 'modalStackState',
    default: [],
});

export const currentMenuState = atom({
    key: "CURRENT_MENU",
    default: ''
});

export const isDataFetchingState = atom({
    key: "IS_DATA_FETCHING",
    default: false
});

export const snackbarState = atom({
    key: "Snackbar_State",
    default: { message: '', variant: '' }
});
