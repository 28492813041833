import toast from 'react-hot-toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../constants/constant';

export default function showToast(message, type) {
    switch (type) {
        case SUCCESS_VARIANT:
            toast.success(message);
            break;
        case ERROR_VARIANT:
            toast.error(message);
            break;
        case 'loading':
            toast.loading();
            break;
        default:
            toast(message);
            break;
    }
}

