import { featureInstance } from "./AxiosInstance";

// <--------- Get All Features --------->
export const getAllFeaturesApi = async (page = 0, size = 100) => {
    try {
        const result = await featureInstance.get(`features?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Add New Feature --------->
export const addNewFeatureApi = async (body) => {
    try {
        const result = await featureInstance.post('features', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}