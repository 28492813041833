import React, { useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css';
import moment from "moment";


const DatePickerComponent = ({ selectedDate, onDateChange, placeholder = "Select DOB" }) => {
    const [date, setDate] = useState(selectedDate || null);

    const handleDateChange = (date) => {
        setDate(date);
        const formattedDate = moment(date).format("YYYY-MM-DD");
        onDateChange(formattedDate);
    };

    return (
        <div className="date-picker-wrapper">
            <DatePicker
                selected={date}
                onChange={handleDateChange}
                dateFormat="do MMM yyyy"
                maxDate={new Date()} // Restrict to dates up to today
                showYearDropdown
                showMonthDropdown
                placeholderText={placeholder}
                className="rounded-md border-[1px] flex justify-center font-quicksand text-sm px-3 h-10 w-32 outline-none border-gray-200 bg-white"
                dropdownMode="select"
                calendarClassName="custom-calendar"
            />
        </div>
    );
};

export default DatePickerComponent;
