import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import CustomModal from '../modals/CustomModal';
import Pagination from '../common/Pagination';
import { getAllUsersApi } from '../../rest/UserApi';
import AssignRoleDataTable from './AssignRoleDataTable';
import { userActionButtonList } from '../../constants/dummyData';
import NoDataFound from '../common/NoDataFound';
import Loader from '../common/Loader';
import { customBlue } from '../../constants/color';

// Search component for global filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <div className='flex justify-end px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg'>
            <div className='flex items-center h-8'>
                <input
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                    className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                    placeholder="Search..."
                />
                <button
                    className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                >
                    <Icon
                        icon='mingcute:search-line'
                        color='white'
                        fontSize={20}
                    />
                </button>
            </div>
        </div>
    );
};

const UserDataTable = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentMenu, setCurrentMenu] = useState('ASSIGN');
    const [currentUser, setCurrentUser] = useState(null);

    // Define columns and data
    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <div className='flex items-center'>
                    {
                        row.original.name.image ? (
                            <img
                                src={row.original.name.image}
                                className="w-10 h-10 rounded-full"
                            />
                        ) : (
                            <img
                                src={'../assets/images/profile.png'}
                                className="w-10 h-10 rounded-full"
                            />
                        )
                    }

                    <span className='text-sm font-quicksand font-semibold ml-3'>
                        {row.original.name.userName}
                    </span>
                </div>
            ),
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Role',
            accessor: 'roles',
            Cell: ({ row }) => (
                <div className='flex flex-wrap gap-2'>
                    {
                        row.original.roles.map((item, index) => {
                            return (
                                <div
                                    className='h-7 px-4 flex justify-center items-center rounded-md bg-gray-100'>
                                    <div
                                        className='font-quicksand font-bold text-xs'>
                                        {item}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            ),
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: memo(({ row }) => {
                return (
                    <div
                        className='flex items-center gap-1'>
                        {
                            userActionButtonList.map((item, index) => {
                                return (
                                    <div
                                        onClick={(event) => handleActionClick(event, item.action, row.original)}
                                        className='h-9 w-9 relative group flex justify-center items-center cursor-pointer hover:bg-gray-200 rounded-full bg-opacity-10'>
                                        <Icon
                                            icon={item.icon}
                                            color='gray'
                                            fontSize={22}
                                        />
                                        <div
                                            className="absolute z-40 whitespace-nowrap top-full left-1/2 transform -translate-x-1/2 mt-1 bg-gray-500 text-white text-xs font-medium py-1 px-2 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                        >
                                            {item.toolTip}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })
        },
    ], [data, setData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageSize } = state;

    function setUserData(users) {
        const formattedUsersData = users.map(({ profileImage, name, ...rest }) => ({
            ...rest,
            name: {
                userName: name,
                profileImage: profileImage
            }
        }));

        setData(formattedUsersData);
    }

    // Fetch roles data from the API
    const fetchData = async () => {
        setIsLoading(true);
        const result = await getAllUsersApi(pageNumber, pageSize);
        if (result?.status === 200) {
            setUserData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    function handleActionClick(event, menu, user) {
        event.stopPropagation();
        setCurrentMenu(menu.toUpperCase());
        setCurrentUser(user);
        setIsModalOpen(true);
    }

    return (
        <div className="p-8 bg-white rounded-xl mt-8">

            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
            />

            {isModalOpen && (
                <CustomModal
                    width={currentMenu === 'ASSIGN' ? 'w-[60%]' : 'w-[500px]'}
                    showCross={true}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}>
                    {
                        currentMenu === 'ASSIGN' && (
                            <AssignRoleDataTable
                                setUserData={setData}
                                userDetail={currentUser}
                            />
                        )
                    }
                </CustomModal>
            )}

            {/* Data Table */}
            <div className="overflow-x-auto mt-4 pb-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                    >
                                        <div className='flex items-center'>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? <Icon
                                                        icon='eva:arrow-down-fill'
                                                        color='gray'
                                                        fontSize={14}
                                                    /> : <Icon
                                                        icon='eva:arrow-up-fill'
                                                        color='gray'
                                                        fontSize={14}
                                                    />
                                                ) : (
                                                    <Icon
                                                        icon='flowbite:sort-outline'
                                                        color='gray'
                                                        fontSize={14}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            isLoading ? (
                                <tr>
                                    <td
                                        colSpan={columns.length} // Spans all columns
                                        className="px-4 py-10 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                    >
                                        <Loader
                                            color={customBlue}
                                            size="2.5rem"
                                            borderWidth='3px'
                                        />
                                    </td>
                                </tr>
                            ) :
                                page.length > 0 ? (
                                    page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="hover:bg-gray-50">
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300"
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })) : (
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                            className='border-b border-gray-300'
                                        >
                                            <NoDataFound
                                                message={'No User Found'}
                                            />
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-4">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    );
};

export default UserDataTable;
