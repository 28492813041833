import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { forgotPasswordApi } from '../../rest/AuthApi'
import { ERROR_VARIANT, SOMETHING_WENT_WRONG } from '../../constants/constant'
import { CustomButton, InputField, Label } from '../common/Form'
import showToast from '../../utils/toast'
import { useNavigate } from 'react-router-dom'

export default function ForgotPasswordForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        data.email = data.email.toLowerCase();
        setIsLoading(true)
        const result = await forgotPasswordApi(data);
        if (result.status === 200) {
            const param = { email: data.email };
            navigate('/verifyforgotpasswordotp', { replace: true, state: param });
        }
        else if (result.data.code === 'NOT_FOUND')
            showToast('No Account Found', ERROR_VARIANT);
        setIsLoading(false);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex-col justify-center'>
            <div
                className='text-center font-quicksand font-bold text-black text-2xl mt-8'>
                Forgot Password
            </div>

            <h3
                className='text-center font-quicksand-semiBold text-gray-400 mt-2 text-sm'>
                Enter the email address associated with your account. You will receive an OTP (One-Time Password) to reset your password.
            </h3>

            <div className='mt-6'>
                <Label text='Email' />
                <InputField
                    register={register}
                    name={'email'}
                    placeholder={'Enter your email address...'}
                    pattern={{
                        required: "Your email is the key—don’t leave it out!",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    }}
                    errors={errors}
                />
            </div>


            <div className='mt-6'>
                <CustomButton
                    text='Submit'
                    isLoading={isLoading}
                />
            </div>

        </form>
    )
}