import moment from "moment";
import 'moment-timezone';

export function dateConversion(date) {
    const utcDate = moment.utc(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcDate.tz(timeZone);

    const year = localDate.format('YYYY');
    const month = localDate.format('MM');
    const day = localDate.format('DD');
    const hours = localDate.format('hh');
    const minutes = localDate.format('mm');
    const ampm = localDate.format('A');

    const formattedTime = `${hours}:${minutes} ${ampm}`;
    const formattedDate1 = `${day}-${month}-${year}`;
    const formattedDate2 = `${year}-${month}-${day}`;

    return { formattedDate1, formattedDate2, formattedTime };
}

// <--------- Get Formatted Date(2024-05-31 to 31MAY2024) --------->
export const formatDate = (dateString) => {
    try {
        const date = moment(dateString);

        const dayOfWeek = date.format('dddd');
        const day = date.format('DD');
        const month = date.format('MMM').toUpperCase();
        const year1 = date.format('YYYY');
        const year2 = date.format('YY');

        const dateType1 = `${dayOfWeek} ${day}${month}${year1}`;
        const dateType2 = `${day}${month}${year2}`;

        return { dateType1, dateType2 };
    } catch (error) {
        console.error('Error formatting date:', error);
        return dateString;
    }
};