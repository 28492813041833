// <--------- Icons --------->
// export const coinIcon = require('../assets/icons/common/coin.png');
// export const mailIcon = require('../assets/images/common/mail.png');
// export const trophyIcon = require('../assets/images/common/trophy.png');
// export const starIcon = require('../assets/images/common/star.png');
// export const bulbIcon = require('../assets/images/common/bulb.png');
// export const badgeIcon = require('../assets/images/common/badge.png');
// export const feedbackIcon = require('../assets/images/common/feedback.png');

// <--------- Images --------->
export const avtar = './assets/images/avtar.svg';
export const appLogo = './assets/images/appLogo.svg';
export const appLogoGrayScale = './assets/images/appLogoGrayscale.png';
export const curveBackgroundUp = './assets/images/curve_background_up.svg';
export const curveBackgroundDown = './assets/images/curve_background_down.svg';
export const magnifyingGlass = './assets/images/magnifyingGlass.svg';