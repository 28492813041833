import React, { useState } from 'react';
import Loader from './Loader';
import { Icon } from '@iconify/react/dist/iconify.js';
import useOutsideClick from '../../hooks/useOutsideClick';
import { customYellow } from '../../constants/color';

// <--------- Input Field --------->
export function InputField(
    {
        errors,
        name,
        register,
        pattern,
        defaultValue = '',
        type = 'text',
        placeholder = '',
        multiline = false,
        showError = true,
        element
    }) {

    return (
        <>
            <div
                // style={{ : element ? '0.5rem' : 0 }}
                className={`flex items-center rounded-md border-[1px] h-10 w-full outline-none border-gray-200 bg-white`}>
                {
                    element && (
                        element
                    )
                }
                <input
                    {...register(name, pattern)}
                    type={type}
                    className="border-0 px-3 h-full outline-none w-full rounded-md font-quicksand text-sm"
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                />

            </div>
            {
                showError && errors[name] && (
                    <div className='text-red-500 font-quicksand font-medium text-[11px]'>
                        {errors[name]?.message}
                    </div>
                )
            }
        </>
    )
}

// <--------- Text Area --------->
export function TextArea(
    {
        errors,
        name,
        register,
        pattern,
        placeholder = '',
        multiline = false,
        showError = true,
        rows = 4,
        defaultValue = ''
    }) {

    return (
        <div>
            <textarea
                {...register(name, pattern)}
                className="border px-3 py-2 outline-none w-full rounded-md font-quicksand text-sm"
                placeholder={placeholder}
                rows={rows}
                defaultValue={defaultValue}
            />
            {
                showError && errors[name] && (
                    <div className='text-red-500 font-quicksand font-medium text-[11px]'>
                        {errors[name]?.message}
                    </div>
                )
            }
        </div>
    )
}

// <--------- Label --------->
export function Label({ text }) {
    return (
        <div
            className='flex-row items-center'>
            <h4
                className='font-quicksand text-sm font-bold mb-1 text-[#00000099]'>
                {text}
            </h4>
        </div>
    )
}

// // <--------- Date Field --------->
// export function DateField({ register, errors, name, pattern, handleDateInput, dateValue }) {
//     const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

//     const showDatePicker = () => {
//         setDatePickerVisibility(true);
//     };

//     const hideDatePicker = () => {
//         setDatePickerVisibility(false);
//     };

//     const handleConfirm = (date) => {
//         hideDatePicker();
//     };
//     return (
//         // <div className='mb-5'>
//         //     <div
//         //         style={{ color: dateValue === '' && '#9ca3af' }}
//         //         className='bg-white rounded-lg text-sm px-4 w-full outline-none border dark:border-0 border-gray-300 h-10 custom-date-picker relative flex items-center justify-between'>
//         //         {dateValue ? dateValue : 'Select Date'}
//         //         <img src='./assets/icons/calender.svg' />
//         //         <input
//         //             type='date'
//         //             {...register(name, pattern)}
//         //             error={Boolean(errors[name])}
//         //             onChange={handleDateInput}
//         //         />
//         //     </div>
//         //     {
//         //         errors[name] && <div className='text-[11px] text-red-400 mt-1'>{errors[name]?.message}</div>
//         //     }
//         // </div>
//         <View>
//             <Button title="Show Date Picker" onPress={showDatePicker} />
//             <DateTimePickerModal
//                 isVisible={isDatePickerVisible}
//                 mode="date"
//                 onConfirm={handleConfirm}
//                 onCancel={hideDatePicker}
//             />
//         </View>
//     )
// }

// <--------- Drop Down Menu --------->
export function CustomDropDown({
    label = '',
    dropDownList = [],
    selectedOption,
    setSelectedOption,
    height = 'max-h-60'
}) {
    const { containerRef, menuRef, isMenuOpen, setIsMenuOpen } = useOutsideClick();

    function handleDropDown(option) {
        setSelectedOption(option);
        setIsMenuOpen(false);
    }

    return (
        <div
            className='relative'>
            <Label text={label} />
            <div
                ref={containerRef}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className='flex justify-between items-center h-10 border rounded-md px-3 cursor-pointer'>
                <span className='font-quicksand text-sm'>{selectedOption}</span>
                <Icon
                    icon='iconamoon:arrow-down-2'
                    fontSize={24}
                />
                {
                    isMenuOpen && (
                        <div
                            ref={menuRef}
                            className={`absolute z-10 left-0 top-[4.5rem] ${height} overflow-y-auto rounded-md border w-full bg-white`}>
                            {
                                dropDownList.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => handleDropDown(item)}
                                            className={`flex items-center hover:bg-gray-100 text-sm px-3 cursor-pointer h-10 ${selectedOption === item ? 'bg-gray-100' : 'bg-white'}`}>
                                            {item}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

// <--------- Button --------->
export function CustomButton({
    text = '',
    textColor = 'black',
    isLoading = false,
    onClick = null,
    icon = null,
    height = '2.5rem',
    width = '100%',
    disabled = false,
    backgroundColor = customYellow
}) {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = (e) => {
        setIsClicked(true);
        if (onClick) onClick(e);

        // Reset the click effect after 200ms
        setTimeout(() => {
            setIsClicked(false);
        }, 200);
    };

    return (
        <div className='flex justify-center -z-10'>
            <button
                style={{ height, width, backgroundColor: backgroundColor }}
                className={`flex-row justify-center rounded-md w-full transform transition-all duration-200 ${isClicked ? 'scale-95 opacity-90' : ''}`}
                onClick={handleClick}
                disabled={isLoading || disabled}
            >
                {isLoading ? (
                    <Loader size={'1.4rem'} color='#fff' />
                ) : (
                    <>
                        {icon && icon}
                        <span className='text-center font-quicksand font-medium text-white'>
                            {text}
                        </span>
                    </>
                )}
            </button>
        </div>
    );
}


