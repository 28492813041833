import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { isAuthenticatedState, userState } from '../recoil/store';
import { ACCESS_TOKEN, ERROR_VARIANT, REFRESH_TOKEN } from '../constants/constant';
import store from 'store'
import { replace, useNavigate } from 'react-router-dom';
import { meApi } from '../rest/UserApi';
import { canAccessApp } from '../utils/rolePermissionHandler';
import showToast from '../utils/toast';

export function useTokenManager() {
    const navigation = useNavigate();
    const setIsLoggedIn = useSetRecoilState(isAuthenticatedState);
    const setUser = useSetRecoilState(userState);

    // <--------- Fetch user detail --------->
    const fetchUserDetail = async () => {
        const result = await meApi();
        if (result?.status === 200) {
            if (!canAccessApp(result.data.roles)) {
                removeTokens();
                // showToast('Access denied', ERROR_VARIANT);
                navigation('/unauthorize');
                return;
            }
            setUser(result.data);
            setIsLoggedIn(true);
            navigation('/', replace);
        }
    };

    // <--------- Function to save tokens --------->
    const saveTokens = useCallback(async (accessToken, refreshToken) => {
        try {
            store.set(ACCESS_TOKEN, accessToken);
            store.set(REFRESH_TOKEN, refreshToken);
            await fetchUserDetail();
        } catch (error) {
            console.error('Failed to save tokens:', error);
        }
    }, [setIsLoggedIn]);

    // <--------- Function to remove tokens --------->
    const removeTokens = useCallback(() => {
        try {
            store.remove(ACCESS_TOKEN);
            store.remove(REFRESH_TOKEN);
            setUser(null);
            setIsLoggedIn(false);
            navigation('/', replace);
        } catch (error) {
            console.error('Failed to remove tokens:', error);
        }
    }, [setIsLoggedIn]);

    return { saveTokens, removeTokens };
}
