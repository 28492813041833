import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentMenuState, userState } from '../../recoil/store';
import { sideBarMenuList } from '../../constants/dummyData';
import { avtar } from '../../constants/ImagesAndIcons';
import Profile from './Profile';
import CustomModal from '../modals/CustomModal';
import { BASE_URL } from '../../constants/constant';

export default function Navbar() {
    const currentMenu = useRecoilValue(currentMenuState);
    const getSideBarMenuObj = useCallback(((menuName) => sideBarMenuList?.find(option => option.menuName === menuName)), []);
    const [userDetail, setUserDetail] = useRecoilState(userState);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const refreshImage = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    return (
        <>
            {
                isProfileModalOpen && (
                    <CustomModal
                        width='w-[30rem]'
                        isModalOpen={isProfileModalOpen}
                        setIsModalOpen={setIsProfileModalOpen}>
                        <Profile
                            userDetail={userDetail}
                            setUserDetail={setUserDetail}
                            setIsModalOpen={setIsProfileModalOpen}
                            refreshImage={refreshImage}
                        />
                    </CustomModal>
                )
            }
            <div className='flex justify-between items-center text-gray-700 py-6 px-10 bg-white shadow-sm'>
                <div className='font-quicksand font-bold text-xl'>
                    {getSideBarMenuObj(currentMenu)?.menuName}
                </div>
                <div className='flex justify-center items-center'>

                    <div className='font-quicksand font-semibold text-sm'>
                        {userDetail?.name}
                    </div>

                    <img
                        onClick={() => setIsProfileModalOpen(true)}
                        src={
                            userDetail?.profileImage
                                ? `${BASE_URL}/${userDetail?.profileImage}?key=${refreshKey}`
                                : avtar
                        }
                        className='h-8 w-8 rounded-full ml-4 cursor-pointer'
                        alt='user profile'
                    />
                </div>
            </div>
        </>
    )
}
