import React, { useState } from 'react';
import { speechText } from '../../constants/dummyData';
import { CustomButton } from '../common/Form';
import { appLogoGrayScale } from '../../constants/ImagesAndIcons';
import { BASE_URL, FACT_IMAGE_URL } from '../../constants/constant';
import CustomModal from '../modals/CustomModal';
import ImageViewer from '../common/ImageViewer';

export default function FactDetails({ factDetails }) {
    const imageUrl = factDetails?.coverUrl ? `${FACT_IMAGE_URL}${factDetails?.coverUrl}` : appLogoGrayScale;
    const [isImageViewerModalOpen, setIsImageViewerModalOpen] = useState(false);

    function handleSourceUrl(url) {
        window.open(url, '_blank');
    }

    return (
        <>
            {
                isImageViewerModalOpen && (
                    <CustomModal
                        width='full'
                        isModalOpen={isImageViewerModalOpen}
                        setIsModalOpen={setIsImageViewerModalOpen}>
                        <ImageViewer
                            imgUrl={imageUrl}
                        />
                    </CustomModal>
                )
            }

            <div
                onClick={() => setIsImageViewerModalOpen(true)}
                className='relative'>
                <img
                    src={imageUrl}
                    className="w-full aspect-video rounded-lg object-cover cursor-pointer"
                    alt="Selected"
                />

                <div className='absolute top-4 left-4 bg-[#0000005a] bg-opacity-50 rounded-[20px] px-4 py-[0.4rem] text-gray-100 text-sm font-quicksand font-medium'>
                    {factDetails?.categories?.[0] || 'Category'}
                </div>
            </div>

            <div className='mt-2 flex-1'>
                <div className='flex flex-wrap gap-2 mt-4'>
                    {
                        factDetails?.tags?.map((item, index) => (
                            <div
                                key={index}
                                className='h-6 px-3 flex justify-center items-center rounded-[12px] bg-gray-200'>
                                <span className='font-quicksand font-medium text-gray-600 text-xs'>
                                    {item}
                                </span>
                            </div>
                        ))
                    }
                </div>

                <div
                    className='font-quicksand font-bold text-black text-xl mt-6'>
                    {factDetails?.title}
                </div>

                <p
                    className='font-quicksand text font-bold text-black mt-6 w-24 whitespace-pre-wrap'>
                    Summary
                </p>

                <p
                    className='font-quicksand text-sm font-semibold text-[#727272] mt-2 whitespace-pre-wrap'>
                    {factDetails?.description}
                </p>

                <p
                    className='font-quicksand text font-bold text-black mt-6 w-24 whitespace-pre-wrap'>
                    Description
                </p>

                <p
                    className='font-quicksand text-sm font-semibold text-[#727272] mt-2 mb-6 whitespace-pre-wrap'>
                    {factDetails?.article}
                </p>

                <div className='flex justify-center gap-4'>
                    {
                        factDetails?.sourceUrl && (
                            <div>
                                <CustomButton
                                    onClick={() => handleSourceUrl(factDetails?.sourceUrl)}
                                    text='Visit Source'
                                    textColor='black'
                                    width='8rem'
                                />
                            </div>
                        )

                    }
                </div>
            </div>
        </>
    )
}
