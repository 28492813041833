import { useState, useEffect } from 'react';
import zxcvbn from 'zxcvbn';

const usePasswordStrength = (watch) => {
    const [passwordStrength, setPasswordStrength] = useState(null);

    const passwordValue = watch('password');

    const getPasswordStrength = (password) => {
        const { score } = zxcvbn(password);
        switch (score) {
            case 0:
            case 1:
                return { text: 'Weak', isAllowed: false, color: 'red' };
            case 2:
                return { text: 'Medium', isAllowed: true, color: 'orange' };
            case 3:
            case 4:
                return { text: 'Strong', isAllowed: true, color: 'green' };
            default:
                return null;
        }
    };

    useEffect(() => {
        if (passwordValue) {
            const strength = getPasswordStrength(passwordValue);
            setPasswordStrength(strength);
        } else {
            setPasswordStrength(null);
        }
    }, [passwordValue]);

    return passwordStrength;
};

export default usePasswordStrength;
