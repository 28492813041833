import React, { useEffect, useState } from 'react'
import { avtar } from '../../constants/ImagesAndIcons';
import { CustomButton, InputField, Label, TextArea } from './Form';
import { useForm } from 'react-hook-form';
import DatePickerComponent from './datePicker/DatePicker';
import { ReactCrop } from 'react-image-crop';
import useMediaPicker from '../../hooks/useMediaPicker';
import { BASE_URL, ERROR_VARIANT, PROFILE_IMAGE_INFO, SUCCESS_VARIANT } from '../../constants/constant';
import { updateUserProfileApi, updateUserProfileImageApi } from '../../rest/UserApi';
import showToast from '../../utils/toast';
import ImageViewer from './ImageViewer';
import CustomModal from '../modals/CustomModal';

export default function Profile({ userDetail, setUserDetail, refreshImage }) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: userDetail?.name || '',
            bio: userDetail?.bio || ''
        }
    });
    const [gender, setGender] = useState(userDetail?.gender || '');
    const [dob, setDob] = useState(userDetail?.dob || null);
    const [isImageViewerModalOpen, setIsImageViewerModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        showCropper,
        setShowCropper,
        crop,
        setCrop,
        handleCrop,
        onGallery,
        imageFile,
        imageUrl,
        onImageLoad,
        fileInputRef
    } = useMediaPicker(PROFILE_IMAGE_INFO);
    const imgUrl = imageFile ? imageFile?.filePath : userDetail?.profileImage ? `${BASE_URL}/${userDetail?.profileImage}` : avtar;

    // <--------- Update Profile Image --------->
    async function updateProfileImage() {
        const formData = new FormData();
        formData.append('profileImage', imageFile?.file);

        const result = await updateUserProfileImageApi(formData);
        if (result?.status === 200) {
            setUserDetail(prevData => ({ ...prevData, profileImage: `v1/users/profile/image/${userDetail?.id}.jpg` }))
            showToast('Profile pic updated', SUCCESS_VARIANT);
            refreshImage();
        }
        else
            showToast('Profile pic not updated', ERROR_VARIANT);
    }

    useEffect(() => {
        if (imageFile) {
            updateProfileImage();
        }
    }, [imageFile])

    // <--------- Handle Date --------->
    const handleDateChange = (date) => {
        setDob(date);
    };

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // <--------- Handle Form Submit --------->
    async function onSubmit(data) {
        if (dob)
            data.dob = dob;
        if (gender)
            data.gender = gender;

        setIsLoading(true);
        const result = await updateUserProfileApi(data);
        if (result?.status === 200) {
            setUserDetail(prevData => ({ ...prevData, ...data }))
            showToast('Profile updated', SUCCESS_VARIANT);
        }
        setIsLoading(false);
    }

    // <--------- Image Cropper JSX --------->
    const imageCropperJsx = (
        showCropper && (
            <div className='fixed z-40 top-[5vh] right-[18vw] bottom-[5vh] left-[18vw] flex flex-col justify-center p-8 rounded-xl bg-gray-100'>
                <div className='flex justify-center mb-6'>
                    <ReactCrop
                        src={imageUrl}
                        crop={crop}
                        keepSelection
                        aspect={PROFILE_IMAGE_INFO.ratio}
                        onChange={(pixelCrop, percentageCrop) => setCrop(percentageCrop)}
                        onComplete={() => { }}
                    >
                        <img
                            src={imageUrl}
                            alt='upload'
                            onLoad={onImageLoad}
                            style={{ maxHeight: '75vh' }}

                        />
                    </ReactCrop>
                </div>
                <div className='flex justify-center'>
                    <div
                        onClick={handleCrop}
                        className='bg-custom-blue cursor-pointer text-white text-sm font-quicksand font-medium px-3 py-1 rounded-md'>
                        Crop & Save
                    </div>
                    <div
                        onClick={() => setShowCropper(false)}
                        className='bg-gray-200 cursor-pointer text-gray-800 text-sm ml-3 font-quicksand font-medium px-3 py-1 rounded-md'>
                        Cancel
                    </div>
                </div>
            </div>
        )
    )

    return (
        <div className='p-6 my-4'>
            {imageCropperJsx}
            {
                isImageViewerModalOpen && (
                    <CustomModal
                        width='full'
                        isModalOpen={isImageViewerModalOpen}
                        setIsModalOpen={setIsImageViewerModalOpen}>
                        <ImageViewer
                            imgUrl={imgUrl}
                        />
                    </CustomModal>
                )
            }

            <div className='flex justify-center items-center'>
                <img
                    onClick={() => setIsImageViewerModalOpen(true)}
                    src={imgUrl}
                    className='h-32 w-32 rounded-full cursor-pointer shadow-lg'
                    alt='profile'
                />
            </div>

            <div className='flex justify-center mt-6'>
                <div
                    onClick={handleDivClick}
                    className='flex justify-center items-center text-gray-700 rounded-[20px] font-quicksand font-medium text-xs bg-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-200'>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onGallery}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                    />
                    Change Photo
                </div>
            </div>

            <h3 className='font-quicksand font-medium text-xs text-center mt-3'>
                {userDetail?.email}
            </h3>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className='flex-col justify-center px-8'>

                <div className='mt-6'>
                    <Label text='Name' />
                    <InputField
                        register={register}
                        name={'name'}
                        placeholder={'Enter your name...'}
                        pattern={{
                            required: "Don’t leave me blank! Your name is a must."
                        }}
                        errors={errors}
                    />
                </div>

                <div className='flex mt-4'>
                    <div className='w-1/2'>
                        <Label text='Date of birth' />
                        <DatePickerComponent
                            selectedDate={dob}
                            onDateChange={handleDateChange}
                        />
                    </div>
                    <div className='w-1/2'>
                        <Label text='Gender' />
                        <div className='flex gap-4'>
                            <div
                                onClick={() => setGender('MALE')}
                                className='flex items-center cursor-pointer'>
                                <div
                                    className={`rounded-full h-5 w-5 mr-2 
                                        ${gender === 'MALE' ? 'border-4 border-custom-blue' : 'border'}`}
                                />
                                <span className='font-quicksand text-sm text-gray-700'>
                                    Male
                                </span>
                            </div>
                            <div
                                onClick={() => setGender('FEMALE')}
                                className='flex items-center cursor-pointer'>
                                <div
                                    className={`rounded-full h-5 w-5 mr-2 
                                        ${gender === 'FEMALE' ? 'border-4 border-custom-blue' : 'border'}`}
                                />
                                <span className='font-quicksand text-sm text-gray-700'>
                                    Female
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <Label text='Bio' />
                    <TextArea
                        name='bio'
                        placeholder='Enter your bio...'
                        register={register}
                        errors={errors}
                        rows={2}
                    />
                </div>

                <div className='mt-6'>
                    <CustomButton
                        text="Save"
                        width='6rem'
                        isLoading={isLoading}
                    />
                </div>
            </form>



        </div>
    )
}
