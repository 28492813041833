import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { customBlue } from '../../constants/color';
import { sideBarMenuList } from '../../constants/dummyData';
import { useNavigate } from 'react-router-dom';
import { currentMenuState, userState } from '../../recoil/store';
import { useRecoilValue } from 'recoil';
import { useTokenManager } from '../../hooks/useTokenManager';
import ConfirmationModal from '../modals/ConfirmationModal';
import CustomModal from '../modals/CustomModal';
import { isPermissionGranted } from '../../utils/rolePermissionHandler';

export default function SideBar() {
    const navigate = useNavigate();
    const user = useRecoilValue(userState);
    const currentMenu = useRecoilValue(currentMenuState);
    const { removeTokens } = useTokenManager();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    function handleConfirmation() {
        removeTokens();
    }

    return (
        <>
            {isConfirmationModalOpen && (
                <CustomModal
                    width='w-[24rem]'
                    showCross={false}
                    isModalOpen={isConfirmationModalOpen}
                    setIsModalOpen={setIsConfirmationModalOpen}>
                    {
                        <ConfirmationModal
                            title='Logout?'
                            message={'Are you sure you want to log out?'}
                            setIsModalOpen={setIsConfirmationModalOpen}
                            handleConfirmation={handleConfirmation}>
                        </ConfirmationModal>
                    }
                </CustomModal>
            )}

            <div className='flex flex-col bg-custom-primary-dark w-[300px] py-12'>
                <div className='font-courgette font-bold text-white text-3xl ml-12'>
                    Factaa
                </div>
                {/* <div
                    className='ml-12'>
                    <span className='font-courgette font-bold text-white text-3xl bg-gradient-to-r from-custom-yellow  to-custom-blue bg-clip-text text-transparent'>
                        Factaa
                    </span>
                </div> */}
                {/* <div className='font-quicksand font-bold text-custom-blue text-2xl ml-12'>
                    All
                    <span className='text-custom-yellow'>
                        Facts
                    </span>
                </div> */}

                <div className='mt-16 flex-grow flex flex-col justify-between mb-16'>
                    <div>
                        {
                            sideBarMenuList.map((item, index) => {
                                return (
                                    isPermissionGranted(user?.roles, item.permission) && (
                                        <div
                                            key={index}
                                            className='group flex items-center h-11 mt-4 cursor-pointer'
                                            onClick={() => navigate(item.url)}>
                                            <div
                                                className={`w-[5px] h-full ${currentMenu === item.menuName ? 'bg-custom-blue' : 'bg-custom-primary-dark'} group-hover:bg-custom-blue rounded-r-lg transition-colors`}
                                            />
                                            <div className='flex items-center ml-10'>
                                                <Icon
                                                    icon={item.icon}
                                                    color={customBlue}
                                                    fontSize={20}
                                                />
                                                <div
                                                    className={`font-quicksand font-semibold ${currentMenu === item.menuCode ? 'text-custom-blue' : 'text-gray-100'} text-sm group-hover:text-custom-blue ml-2 transition-colors`}>
                                                    {item.menuName}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            })
                        }
                    </div >
                    <div
                        onClick={() => setIsConfirmationModalOpen(true)}
                        className='flex items-center ml-[45px] cursor-pointer mr-24 mt-16'>
                        <Icon
                            icon='humbleicons:logout'
                            color={'#ef4444'}
                            fontSize={24}
                        />
                        <div
                            className='font-quicksand font-semibold text-red-500 text-sm group-hover:text-custom-blue ml-2 transition-colors'>
                            Logout
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
