import axios from "axios";
import { authInstance } from "./AxiosInstance";

// <--------- User Registration --------->
export const userRegistrationApi = async (body) => {
    try {
        const result = await authInstance.post('/signup', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- User Login --------->
export const userLoginApi = async (body) => {
    try {
        const result = await authInstance.post('/signin', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Verify OTP --------->
export const verifyOTPApi = async (body) => {
    try {
        const result = await authInstance.post('/verify-email', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Verify Forgot Password OTP --------->
export const verifyForgotPasswordOTPApi = async (body) => {
    try {
        const result = await authInstance.post('/verify-password-otp', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Resend OTP --------->
export const resendOTPApi = async (body) => {
    try {
        const result = await authInstance.post('/resend-email-verification', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Forgot Password --------->
export const forgotPasswordApi = async (body) => {
    try {
        const result = await authInstance.post('/forgot-password', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Reset Password --------->
export const resetPasswordApi = async (body) => {
    try {
        const result = await authInstance.post('/reset-password', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}