import React, { useEffect, useState } from "react";
import { useTokenManager } from "../../hooks/useTokenManager";
import { resendOTPApi, verifyOTPApi } from "../../rest/AuthApi";
import { ERROR_VARIANT, SUCCESS_VARIANT } from "../../constants/constant";
import { CustomButton } from "../common/Form";
import OTPInput from "react-otp-input";
import showToast from "../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { customBlue } from "../../constants/color";

export default function OTPVerificationForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const param = location.state;
    const { saveTokens } = useTokenManager();
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(60 * 14 + 59);
    const [isOtpExpired, setIsOtpExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOtpChange = (text) => {
        setOtp(text);
    };

    async function handleVerifyOtp() {
        setIsLoading(true);
        const result = await verifyOTPApi({ email: param?.email, otp: otp });
        if (result?.status === 200) {
            saveTokens(result?.data?.accessToken, result?.data?.refreshToken);
        }
        else if (result?.data?.code === 'INVALID_OTP')
            showToast('Oops! Wrong OTP', ERROR_VARIANT);
        setIsLoading(false);
    };

    async function handleReSendOtp(otpStatus) {
        if (otpStatus === 'OTP_EXPIRED')
            setIsLoading(true)

        const result = await resendOTPApi({ email: param?.email });
        if (result?.status === 200) {
            showToast('OTP Sent! Check Your Inbox', SUCCESS_VARIANT);
            setSeconds(60 * 14 + 59);
            setIsOtpExpired(false);
        }
        else if (result?.data?.code === 'DEFAULT')
            showToast('Your Email is Verified');
        setIsLoading(false);
    };

    useEffect(() => {
        let interval = null;
        if (seconds === 0) {
            setIsOtpExpired(true);
            clearInterval(interval);
        } else {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
        if (!param) {
            navigate('/login');
        }
    }, [param]);

    if (!param) return null;

    const OTPNotExpiredJsx = (
        <>
            <h6
                className="text-custom-blue text-center font-quicksand font-medium text-sm mt-4"
            >
                {Math.floor(seconds / 60)} min {seconds % 60} sec
            </h6>
            <div className="flex justify-center mt-4">
                <OTPInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    containerStyle={{ gap: '1rem' }}
                    inputStyle={{
                        borderRadius: '6px',
                        borderColor: customBlue,
                        borderWidth: 1,
                        width: '2rem',
                        height: '2rem',
                        outline: 'none',
                        fontSize: '14px'
                    }}
                    renderInput={(props) => <input {...props} />}
                />
            </div>

            <div className="mt-6">
                <CustomButton
                    text="Verify"
                    // height={scale(40)}
                    onClick={handleVerifyOtp}
                    isLoading={isLoading}
                    disabled={otp.length !== 4}
                />
            </div>

            <h6
                className="text-black text-center mt-4 font-quicksand font-medium text-sm"
            >
                Didn’t get e-mail?
                <span
                    onClick={() => handleReSendOtp('OTP_NOT_EXPIRED')}
                    className="text-custom-blue font-quicksand font-bold cursor-pointer"
                >
                    {` Send it again`}
                </span>
            </h6>
        </>
    );

    const OTPExpiredJsx = (
        <div>
            <h6
                className="text-red-500 text-center font-quicksand font-bold mt-4 text-sm"
            >
                OTP Expired
            </h6>
            <div className="mt-6">
                <CustomButton
                    text="Resend Email"
                    onClick={() => handleReSendOtp('OTP_EXPIRED')}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );

    return (
        <div className="flex-col justify-center bg-white mt-8">
            <div
                className="flex-1 justify-center"
            >
                <div className="flex justify-center items-center">
                    <div
                        className="h-24 w-24 flex justify-center items-center rounded-full bg-custom-light-blue"
                    >
                        <img src="./assets/images/mail.png"
                            className="h-14  w-14  mt-3 ml-1"
                        />
                    </div>
                </div>

                <h2
                    className="text-black text-center font-quicksand font-bold mt-6 text-xl"
                >
                    Check your inbox, please!
                </h2>

                <h6

                    className="text-gray-400 text-center font-quicksand text-base font-semibold mt-2"
                >
                    {`Hey${param?.name ? ' ' + param?.name : ''}, to start using AllFacts, please verify your email with the OTP we've sent.`}
                </h6>

                {isOtpExpired ? OTPExpiredJsx : OTPNotExpiredJsx}

            </div>
        </div>
    );
}
