import { useEffect, useState } from "react";
import { Navigate, replace, Route, Routes, useNavigate } from "react-router-dom";
import FactPage from "./pages/FactPage";
import UserPage from "./pages/UserPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnprotectedRoute from "./routes/UnProtectedRoute";
import DashboardPage from "./pages/DashboardPage";
import RolePage from "./pages/RolePage";
import ForgotPasswordForm from "./components/forms/ForgotPasswordForm";
import ChangePasswordForm from "./components/forms/ChangePasswordForm";
import LoginForm from "./components/forms/LoginForm";
import SignupForm from "./components/forms/SignupForm";
import ForgotPasswordOTPVerificationForm from "./components/forms/ForgotPasswordOTPVerificationForm";
import OTPVerificationForm from "./components/forms/OTPVerificationForm";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isAuthenticatedState, userState } from "./recoil/store";
import { meApi } from "./rest/UserApi";
import Loader from "./components/common/Loader";
import { customBlue } from "./constants/color";
import showToast from "./utils/toast";
import { canAccessApp, initialRoute, isPermissionGranted } from "./utils/rolePermissionHandler";
import { ERROR_VARIANT } from "./constants/constant";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const navigation = useNavigate();
  const setIsLoggedIn = useSetRecoilState(isAuthenticatedState);
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(true);

  // <--------- Fetch user detail --------->
  const fetchUserDetail = async () => {
    setIsLoading(true);
    const result = await meApi();
    if (result?.status === 200) {
      if (!canAccessApp(result.data.roles)) {
        // showToast('Access denied', ERROR_VARIANT);
        navigation('/unauthorize');
        setIsLoading(false);
        return;
      }
      setIsLoggedIn(true);
      setUser(result.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const protectedRouteConfig = [
    {
      path: '/dashboard',
      element: <DashboardPage />,
      permission: 'DASHBOARD_MENU',
    },
    {
      path: '/facts',
      element: <FactPage />,
      permission: 'FACT_MENU',
    },
    {
      path: '/users',
      element: <UserPage />,
      permission: 'USER_MENU',
    },
    {
      path: '/roles',
      element: <RolePage />,
      permission: 'ROLE_MENU',
    },
  ];

  if (isLoading)
    return (
      <div className='flex justify-center items-center h-[100vh] bg-white'>
        <div className=' h-[90vh] w-[90vw] flex justify-center items-center rounded-3xl shadow-2xl'>
          <Loader
            color={customBlue}
            size="3rem"
            borderWidth='3px'
          />
        </div>
      </div>
    )

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Navigate to={initialRoute(user?.roles)} />} />
        {
          protectedRouteConfig.map(({ path, element, permission }) =>
            isPermissionGranted(user?.roles, permission) ? (
              <Route key={path} path={path} element={element} />
            ) :
              <Route path={path} element={<Navigate to="/unauthorize" />} />
          )
        }
      </Route>
      <Route element={<UnprotectedRoute />}>
        <Route path='/login' element={<LoginForm />} />
        <Route path='/signup' element={<SignupForm />} />
        <Route path='/verifyotp' element={<OTPVerificationForm />} />
        <Route path='/forgotpassword' element={<ForgotPasswordForm />} />
        <Route path='/verifyforgotpasswordotp' element={<ForgotPasswordOTPVerificationForm />} />
        <Route path='/changepassword' element={<ChangePasswordForm />} />
      </Route>
      <Route path='/unauthorize' element={<UnauthorizedPage />} />
      <Route path='/*' element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
