module.exports = {
    customPrimaryDark: "#010B13",
    customSecondaryDark: "#23314f",
    customLight: '#F0F3F5',
    customBlue: "#6CA2FF",
    customLightBlue: "#DCE9FF",
    customRed: '#E84C4F',
    customYellow: '#FBC503',
    customOrange: '#FF762D',
    customGreen: '#23AA0D',
    customGray: '#A8A8A8',
    customCyan: '#02FFF0'
};