import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant'
import { CustomButton, InputField, Label } from '../common/Form'
import showToast from '../../utils/toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPasswordApi } from '../../rest/AuthApi'
import usePasswordStrength from '../../hooks/usePasswordStrength'

export default function ChangePasswordForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const param = location.state;
    const [isLoading, setIsLoading] = useState(false);
    const { watch, register, getValues, setError, clearErrors, handleSubmit, formState: { errors } } = useForm();
    const passwordStrength = usePasswordStrength(watch);

    useEffect(() => {
        if (!param) {
            navigate('/login');
        }
    }, [param]);

    if (!param) return null;

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        setIsLoading(true)
        const result = await resetPasswordApi({ email: param?.email, otp: param?.otp, password: data.password });
        if (result?.status === 200) {
            showToast('Password Changed', SUCCESS_VARIANT);
            navigate('/login', { replace: true })
        }
        else if (result?.data?.code === 'NOT_FOUND')
            showToast('No Account Found', ERROR_VARIANT);
        setIsLoading(false);
    }

    function customSubmit() {
        clearErrors();
        const password = getValues('password');
        const confirmPassword = getValues('confirmPassword');

        if (!passwordStrength?.isAllowed && password) {
            setError(`password`, {
                type: "manual",
                message: "Looks like your password needs a little more muscle!"
            });
            return;
        }

        if (password !== confirmPassword) {
            setError(`confirmPassword`, {
                type: "manual",
                message: "Oops! Your passwords don’t match. Let’s make them twins!"
            });
            return;
        }

        handleSubmit(onSubmit)();
    }

    return (
        <form
            onSubmit={handleSubmit(customSubmit)}
            className='flex-col justify-center'>
            <div
                className='text-center font-quicksand font-bold text-black text-2xl mt-8'>
                Change Password
            </div>

            <h3
                className='text-center font-quicksand-semiBold text-gray-400 mt-2 text-sm'>
                To keep your account secure, please create a new password. Enter your new password and confirm it to complete the process.
            </h3>

            <div className='mt-6'>
                <div className='flex'>
                    <Label text='New Password' />
                    {
                        passwordStrength && (
                            <span
                                style={{ color: passwordStrength?.color }}
                                className='font-quicksand text-sm font-semibold ml-3'>
                                {passwordStrength?.text}
                            </span>
                        )
                    }
                </div>
                <InputField
                    style=''
                    type='password'
                    register={register}
                    name={'password'}
                    secureTextEntry={true}
                    placeholder={'Create a secure password...'}
                    pattern={{
                        required: "Your password is required"
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-4'>
                <Label text='Confirm New Password' />
                <InputField
                    style=''
                    type='password'
                    register={register}
                    name={'confirmPassword'}
                    secureTextEntry={true}
                    placeholder={'Re-enter your password...'}
                    pattern={{
                        required: "Let’s double-check that password!"
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Submit'
                    isLoading={isLoading}
                />
            </div>

        </form>
    )
}