import React, { useState } from 'react';
import { Icon } from '@iconify/react';

const AddTags = ({ tags, setTags }) => {
    const [text, setText] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleSubmit = (e) => {
        e?.preventDefault();
        if (text.trim() !== '') {
            setTags([...tags, text.trim()]);
            setText('');
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div
            className={`min-h-10 px-3 gap-2 ${tags.length > 0 ? 'py-3' : ''} flex flex-row flex-wrap items-center 
            rounded-md border overflow-hidden`}
        >
            {tags.map((tag, index) => (
                <div
                    key={index}
                    className="px-3 pr-1 h-6 flex flex-row items-center bg-gray-100 rounded-xl mr-2"
                >
                    <span className="text-sm font-quicksand">{tag}</span>
                    <div
                        onClick={() => removeTag(index)}
                        className="ml-1 h-4 w-4 bg-black bg-opacity-30 flex justify-center items-center rounded-full text-white cursor-pointer"
                    >
                        <Icon
                            icon='charm:cross'
                            color='white'
                            fontSize={15}
                        />
                    </div>
                </div>
            ))}
            <div className="flex-1 h-6">
                <input
                    className="min-w-40 outline-none border-none p-0 text-sm font-quicksand"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit(e);
                        }
                    }}
                    placeholder="Enter relevant tags..."
                    onBlur={() => {
                        setIsFocused(false);
                        handleSubmit();
                    }}
                    onFocus={() => setIsFocused(true)}
                />
            </div>
        </div>
    );
};

export default AddTags;
