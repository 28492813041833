import React from 'react'
import Loader from './Loader'

export default function BackDrop() {
    return (
        <div className='absolute top-0 left-0 bottom-0 right-0 -z-30  flex justify-center items-center bg-[#000000ad]'>
            <Loader size='2.3rem' />
        </div>
    )
}
