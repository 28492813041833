import { roleInstance } from "./AxiosInstance";

// <--------- Get All Roles --------->
export const getAllRolesApi = async (page, size) => {
    try {
        const result = await roleInstance.get(`roles?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get Role By Name --------->
export const getRoleByNameApi = async (roleName) => {
    try {
        const result = await roleInstance.get(`roles/${roleName}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Add New Role --------->
export const addNewRoleApi = async (body) => {
    try {
        const result = await roleInstance.post('roles', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Role --------->
export const updateRoleApi = async (roleId, body) => {
    try {
        const result = await roleInstance.put(`roles/${roleId}`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}