import { getHighestRole } from "../utils/rolePermissionHandler";
import { factInstance } from "./AxiosInstance";

// <--------- Add New Fact --------->
export const addNewFactApi = async (body) => {
    try {
        const result = await factInstance.post('facts', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Repost Fact --------->
export const repostFactApi = async (factId, body) => {
    try {
        const result = await factInstance.put(`facts/${factId}`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get All Facts --------->
export const getAllFactsApi = async (user, page, size) => {
    if (getHighestRole(user?.roles) === 'CONTRIBUTOR') {
        return await getAllFactsByUserApi(user?.id, page, size);
    }
    else {
        try {
            const result = await factInstance.get(`facts?page=${page}&size=${size}`);
            return result;
        } catch (error) {
            return error?.response;
        }
    }

}

// <--------- Get All Facts By User --------->
export const getAllFactsByUserApi = async (userId, page, size) => {
    try {
        const result = await factInstance.get(`facts/users/${userId}?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get Single Fact --------->
export const getSingleFactApi = async (factId) => {
    try {
        const result = await factInstance.get(`facts/${factId}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Cover --------->
export const updateCoverApi = async (factId, body) => {
    try {
        const result = await factInstance.put(`facts/${factId}/cover`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Fact Status --------->
export const updateFactStatusApi = async (factId, body) => {
    try {
        const result = await factInstance.patch(`facts/${factId}/status`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Fact Log --------->
export const getFactLogApi = async (factId, page, size) => {
    try {
        const result = await factInstance.get(`facts/${factId}/logs?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}