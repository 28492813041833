import React from 'react'
import Loader from '../common/Loader';

export default function ConfirmationModal({
  title = '',
  message = '',
  children,
  setIsModalOpen,
  handleConfirmation,
  isLoading = false
}) {

  function handleClose() {
    setIsModalOpen(false);
  }

  return (
    <div className='rounded-lg'>

      <h2
        className='flex items-center h-12 px-4 bg-gray-200 font-quicksand font-semibold'>
        {title}
      </h2>

      {
        children ? children : (
          <p
            className='flex items-center min-h-14 px-4 py-3 bg-white font-quicksand font-medium text-sm'>
            {message}
          </p>
        )
      }

      <div
        className='flex items-center justify-end px-4 h-16 gap-3 border-t-[1px] border-gray-300 '>

        <button
          onClick={handleClose}
          className={`flex-row justify-center h-8 w-28 bg-gray-100 rounded-md transform transition-all duration-200 `}
        >
          <span
            className='text-center font-quicksand font-medium text-black text-sm'>
            Cancel
          </span>
        </button>

        <button
          onClick={handleConfirmation}
          className={`flex-row justify-center h-8 w-28 bg-custom-yellow rounded-md transform transition-all duration-200 `}
        >
          {
            isLoading ? (
              <Loader size={'1.2rem'} color='#fff' />
            ) : (
              <span
                className='text-center font-quicksand font-medium text-white text-sm'>
                Confirm
              </span>
            )
          }
        </button>

      </div>
    </div>
  )
}
