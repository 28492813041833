import { userInstance } from "./AxiosInstance";

// <--------- Get User Info --------->
export const meApi = async () => {
    try {
        const result = await userInstance.get('/users/profile');
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update User Profile --------->
export const updateUserProfileApi = async (body) => {
    try {
        const result = await userInstance.put('/users/profile', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update User Profile Image --------->
export const updateUserProfileImageApi = async (body) => {
    try {
        const result = await userInstance.put('/users/profile/image', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get All Users --------->
export const getAllUsersApi = async (page, size) => {
    try {
        const result = await userInstance.get(`/users?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update User's Role By Id --------->
export const updateUserRoleByIdApi = async (userId, body) => {
    try {
        const result = await userInstance.put(`users/${userId}`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}