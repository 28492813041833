import { useRef, useEffect, useState } from 'react';

const useOutsideClick = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const containerRef = useRef();
    const menuRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return { containerRef, menuRef, isMenuOpen, setIsMenuOpen };
};

export default useOutsideClick;
