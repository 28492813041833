import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import { Icon } from '@iconify/react';
import Pagination from '../common/Pagination';
import { getFactLogApi } from '../../rest/FactApi';
import { isDataFetchingState } from '../../recoil/store';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { dateConversion } from '../../utils/displayDate';
import Loader from '../common/Loader';
import { customBlue } from '../../constants/color';
import NoDataFound from '../common/NoDataFound';

// Search component for global filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <div className={`flex justify-between px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg`}>

            <h3 className='text-lg font-bold font-quicksand'>
                Logs
            </h3>

            <div className='flex items-center h-8'>
                <input
                    value={globalFilter || ''}
                    onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                    className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                    placeholder="Search..."
                />
                <button
                    className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                >
                    <Icon
                        icon='mingcute:search-line'
                        color='white'
                        fontSize={20}
                    />
                </button>
            </div>

        </div>
    );
};

const FactLogDataTable = ({ showAddNewButton = false, factId }) => {

    const [isAddFactModalOpen, setIsAddFactModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isDataFetching, setIsDataFetching] = useRecoilState(isDataFetchingState);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    // Define columns and data
    const columns = useMemo(() => [
        {
            Header: 'Detail',
            accessor: 'detail',
        },
        {
            Header: 'Created By',
            accessor: 'createdByName',
        },
        {
            Header: 'Created Date',
            accessor: 'createdAt',
            Cell: memo(({ row }) => {
                const { formattedDate1, formattedTime } = dateConversion(row.original.createdAt);
                return (
                    <div
                        className='font-quicksand font-semibold text-sm text-gray-700'>
                        {formattedDate1 + ' ' + formattedTime}
                    </div>
                )
            }),
        },
    ], [data, setData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
        state,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageSize } = state;

    // Fetch facts data from the API
    const fetchData = async () => {
        setIsDataFetching(true);
        const result = await getFactLogApi(factId, pageNumber, pageSize);
        if (result?.status === 200) {
            setData(result.data.results);
            setTotalPages(result.data.totalPage);
        }
        setIsDataFetching(false);
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize]);

    return (
        <div className="min-h-[90vh] p-6 bg-white rounded-xl">

            {/* Global Search Filter */}
            <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                isModalOpen={isAddFactModalOpen}
                setIsModalOpen={setIsAddFactModalOpen}
                showAddNewButton={showAddNewButton}
            />

            {/* Data Table */}
            <div className="overflow-x-auto mt-4">
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className="px-4 py-2 bg-white text-left text-sm font-bold text-gray-500 tracking-wider border-b border-gray-300 cursor-pointer font-quicksand "
                                    >
                                        <div className='flex items-center'>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? <Icon
                                                        icon='eva:arrow-down-fill'
                                                        color='gray'
                                                        fontSize={14}
                                                    /> : <Icon
                                                        icon='eva:arrow-up-fill'
                                                        color='gray'
                                                        fontSize={14}
                                                    />
                                                ) : (
                                                    <Icon
                                                        icon='flowbite:sort-outline'
                                                        color='gray'
                                                        fontSize={14}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            isDataFetching ? (
                                <tr>
                                    <td
                                        colSpan={columns.length} // Spans all columns
                                        className="px-4 py-10 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                    >
                                        <Loader
                                            color={customBlue}
                                            size="2.5rem"
                                            borderWidth='3px'
                                        />
                                    </td>
                                </tr>
                            ) :
                                page.length > 0 ? (
                                    page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="hover:bg-gray-50">
                                                {row.cells.map((cell) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="px-4 py-4 text-sm font-quicksand font-semibold text-gray-700 border-b border-gray-300 cursor-pointer"
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                            className='border-b border-gray-300'
                                        >
                                            <NoDataFound
                                                message={'No Log Found'}
                                            />
                                        </td>
                                    </tr>
                                )}
                    </tbody>

                </table>
            </div>

            {/* Pagination Controls */}
            <div className="mt-4">
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>

        </div>
    );
};

export default FactLogDataTable;
