import React, { useCallback, useEffect, useState } from 'react'
import { CustomButton, CustomDropDown, InputField, Label, TextArea } from '../common/Form'
import { permissionsList, userRoleList } from '../../constants/dummyData';
import { useForm } from 'react-hook-form';
import { addNewRoleApi } from '../../rest/RoleApi';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';

export default function AddNewRoleForm({ currentRole = 'USER', allFeatures, setData }) {
    const [isLoading, setIsLoading] = useState()
    const [permissions, setPermissions] = useState(permissionsList);
    const [selectedFeature, setSelectedFeature] = useState('None');
    const [role, setRole] = useState(currentRole);
    const getUserRoleObj = useCallback(((role) => userRoleList?.find(option => option.role === role)), []);
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const featureNames = allFeatures.map(feature => feature.name);
    featureNames.unshift('None');

    useEffect(() => {
        setPermissions(permissionsList);
    }, [selectedFeature])

    function handlePermissions(index) {
        const updatedPermissions = permissions.map((permission, idx) =>
            idx === index
                ? { ...permission, isGranted: !permission.isGranted }
                : permission
        );
        setPermissions(updatedPermissions);
    }

    function toPermissions() {
        const newPermissions = [];
        if (selectedFeature === 'None') return [];
        permissions.map((item) => {
            if (item.isGranted)
                newPermissions.push(`${selectedFeature}.${item.permissionCode}`);
        })
        return newPermissions;
    }

    async function onSubmit(data) {
        data.permissions = toPermissions();
        setIsLoading(true);
        const result = await addNewRoleApi(data);
        if (result?.status === 201) {
            setData((prevData) => [...prevData, data]);
            showToast('Role created', SUCCESS_VARIANT);
            reset();
            setSelectedFeature('None');
        }
        else if (result?.status === 400 && result?.data?.code === 'UNKNOWN') {
            showToast('This role already exists.', ERROR_VARIANT);
        }
        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex-1 bg-white p-6">
            <div className="text-black text-center font-quicksand font-bold text-lg">
                Add new role
            </div>

            <div className="flex-1 justify-between px-3 mt-6">

                <div className='mt-4'>
                    <Label text='Role name' />
                    <InputField
                        style=''
                        register={register}
                        name={'name'}
                        secureTextEntry={true}
                        placeholder={'Enter role name...'}
                        pattern={{
                            required: "Role name is required"
                        }}
                        errors={errors}
                    />
                </div>

                <div className='mt-6'>
                    <CustomDropDown
                        label='Feature'
                        height='max-h-52'
                        dropDownList={featureNames}
                        selectedOption={selectedFeature}
                        setSelectedOption={setSelectedFeature}
                    />
                </div>

                {
                    selectedFeature !== 'None' && (
                        <div className='mt-6'>
                            <Label text='Permissions' />
                            <div className='flex mt-2'>
                                {/* <Permissions
                                    permissions={permissions}
                                    handlePermissions={handlePermissions}
                                /> */}
                                {
                                    permissions.map((permission, idx) => (
                                        <div key={idx} className='flex items-center'>
                                            <input
                                                type='checkbox'
                                                checked={permission.isGranted}
                                                onChange={() => handlePermissions(idx)}
                                                className={`h-4 w-4 mr-2 cursor-pointer checked:bg-sky-500 border-2 rounded-md border-gray-400 checked:border-sky-500`}
                                            />
                                            <div className='font-quicksand font-semibold text-xs mr-4'>
                                                {permission.permissionName}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }

                <div className='mt-6'>
                    <Label text='Description' />
                    <TextArea
                        name='description'
                        placeholder='Enter description...'
                        register={register}
                        errors={errors}
                        rows={4}
                    />
                </div>

                <div className='mt-6'>
                    <CustomButton
                        height='2.4rem'
                        text='Add'
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </form>
    )
}
