import React, { useEffect, useState } from 'react'
import { getSingleFactApi, repostFactApi, updateCoverApi, updateFactStatusApi } from '../../rest/FactApi';
import showToast from '../../utils/toast';
import { ERROR_VARIANT, SUCCESS_VARIANT } from '../../constants/constant';
import EditFactDetailForm from '../forms/EditFactDetailForm';
import EditFactQuestionForm from '../forms/EditFactQuestionForm';
import BackDrop from '../common/BackDrop';
import Loader from '../common/Loader';
import { customBlue } from '../../constants/color';

export default function EditFact({ factId, setData, setIsModalOpen }) {
    const [showFactDetail, setShowFactDetail] = useState(true);
    const [factDetails, setFactDetails] = useState({});
    const [factQuestions, setFactQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFactFetching, setIsFactFetching] = useState(false);

    // <--------- Update Fact Row --------->
    async function updateFactRow(factId, updatedFact) {
        setData(prevData =>
            prevData.map(fact =>
                fact.id === factId ? updatedFact : fact
            )
        );
    }

    // <--------- Fetch fact detail from the API --------->
    const fetchFactDetail = async () => {
        setIsFactFetching(true);
        const result = await getSingleFactApi(factId);
        if (result?.status === 200) {
            setFactDetails(result.data);
        }
        setIsFactFetching(false);
    };

    useEffect(() => {
        fetchFactDetail();
    }, []);

    // <--------- Add Fact Cover --------->
    async function addFactCover(factId, body) {
        const result = await updateCoverApi(factId, body);
        if (result?.status !== 200) {
            showToast('No image uploaded', ERROR_VARIANT);
        }
    }

    // <--------- Form submit function --------->
    async function handleFactSubmission(questions) {
        const data = { ...factDetails, questions };
        const imageFile = data.imageFile;
        const formData = new FormData();
        if (imageFile) {
            delete data.imageFile;
            formData.append('cover', imageFile);
        }

        setIsLoading(true);
        const result = await repostFactApi(factId, data);
        if (result?.status === 200) {
            if (imageFile)
                await addFactCover(factId, formData);
            await updateFactRow(factId, result.data);
            showToast('Fact edited', SUCCESS_VARIANT);
            setIsModalOpen(false);
        }
        setIsLoading(false);
    }

    if (isFactFetching)
        return (
            <div className='flex justify-center items-center h-[90vh]'>
                <Loader
                    color={customBlue}
                    size="3rem"
                    borderWidth='3px'
                />
            </div>
        )

    return (
        <>
            <div style={{ display: showFactDetail ? 'block' : 'none' }}>
                <EditFactDetailForm
                    factDetails={factDetails}
                    setFactDetails={setFactDetails}
                    setShowFactDetail={setShowFactDetail}
                />
            </div>

            <div style={{ display: !showFactDetail ? 'block' : 'none' }}>
                <EditFactQuestionForm
                    factDetails={factDetails}
                    setShowFactDetail={setShowFactDetail}
                    setFactQuestions={setFactQuestions}
                    isLoading={isLoading}
                    handleFactSubmission={handleFactSubmission}
                />
            </div>
        </>
    )
}
