import React, { useEffect, useState } from 'react'
import FactQuestion from './FactQuestion'
import FactDetails from './FactDetails'
import { getSingleFactApi } from '../../rest/FactApi';
import BackDrop from '../common/BackDrop';
import Loader from '../common/Loader';
import { customBlue } from '../../constants/color';

export default function ViewFact({ factId, setData }) {
    const [factDetails, setFactDetails] = useState({})
    const [currentTab, setCurrentTab] = useState('DETAILS');
    const [isFactFetching, setIsFactFetching] = useState(false);


    // <--------- Fetch fact detail from the API --------->
    const fetchFactDetail = async () => {
        setIsFactFetching(true);
        const result = await getSingleFactApi(factId);
        if (result?.status === 200) {
            setFactDetails(result.data);
        }
        setIsFactFetching(false);
    };

    useEffect(() => {
        fetchFactDetail();
    }, []);

    if (isFactFetching)
        return (
            <div className='flex justify-center items-center h-[90vh]'>
                <Loader
                    color={customBlue}
                    size="3rem"
                    borderWidth='3px'
                />
            </div>
        )

    return (
        <div className="min-h-[90vh] p-6 bg-white">

            {
                factDetails.questions && (
                    <div
                        className='rounded-lg h-10 flex justify-between items-center overflow-hidden border border-gray-200 cursor-pointer mb-6'>
                        <div
                            onClick={() => setCurrentTab('DETAILS')}
                            className={`font-quicksand flex justify-center items-center text-base font-medium w-1/2 border-4 rounded-lg border-white ${currentTab === 'DETAILS' ? 'bg-gray-200' : ''} h-full`}>
                            Details
                        </div>
                        <div
                            onClick={() => setCurrentTab('QUESTIONS')}
                            className={`font-quicksand flex justify-center items-center text-base font-medium w-1/2 border-4 rounded-lg border-white ${currentTab === 'QUESTIONS' ? 'bg-gray-200' : ''} h-full`}>
                            Questions
                        </div>
                    </div>
                )
            }

            {
                currentTab === 'DETAILS' ? (
                    <FactDetails
                        factDetails={factDetails}
                    />
                ) : (
                    factDetails?.questions?.map((item, index) => {
                        return (
                            <FactQuestion
                                question={item}
                                questionIndex={index}
                            />
                        )
                    })
                )
            }

        </div>
    )
}
