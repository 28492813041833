import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { permissionsList } from '../../constants/dummyData';
import Permissions from './Permissions';
import CustomModal from '../modals/CustomModal';
import AddNewFeatureForm from '../forms/AddNewFeatureForm';
import NoDataFound from '../common/NoDataFound';
import Loader from '../common/Loader';
import { customBlue } from '../../constants/color';

export default function Feature({ allFeatures, setAllFeatures, selectedRole, setSelectedRole, isFeatureFetching }) {
    const [searchText, setSearchText] = useState('');
    const [isAddFeatureModalOpen, setIsAddFeatureModalOpen] = useState(false);
    const [filteredFeature, setFilteredFeature] = useState(allFeatures);

    function featureSearch() {
        const feature = allFeatures.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredFeature(feature);
    }

    useEffect(() => {
        featureSearch();
    }, [searchText, allFeatures]);

    return (
        <div className='bg-white rounded-xl p-8'>

            {isAddFeatureModalOpen && (
                <CustomModal
                    width='w-[28rem]'
                    isModalOpen={isAddFeatureModalOpen}
                    setIsModalOpen={setIsAddFeatureModalOpen}
                >
                    <AddNewFeatureForm
                        setAllFeatures={setAllFeatures}
                    />
                </CustomModal>
            )}

            <div className={`flex justify-between px-6 items-center h-[4.5rem] bg-gray-50 rounded-lg mb-4`}>
                <div className='flex items-center h-8'>
                    <input
                        value={searchText || ''}
                        onChange={(e) => setSearchText(e.target.value || '')}
                        className="px-4 py-1 h-8 min-w-56 border border-gray-400 rounded outline-none text-sm font-quicksand"
                        placeholder="Search..."
                    />
                    <button
                        className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    >
                        <Icon
                            icon='mingcute:search-line'
                            color='white'
                            fontSize={20}
                        />
                    </button>
                </div>

                <div className='h-8'>
                    <button
                        onClick={() => setIsAddFeatureModalOpen(true)}
                        className="px-3 h-full ml-2 bg-custom-blue text-white rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    >
                        Add new feature
                    </button>
                </div>
            </div>

            <table className='min-w-full table-fixed'>
                <thead className='bg-gray-50 '>
                    <tr>
                        <th className='font-quicksand font-bold text-left p-4 w-1/5'>Features</th>
                        <th className='font-quicksand font-bold text-left p-4 w-4/5' colSpan={4}>Permissions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFeatureFetching ? (
                            <tr>
                                <td
                                    colSpan={5}
                                    className="px-4 py-6 font-quicksand font-semibold text-gray-700 border-b border-gray-300 text-center"
                                >
                                    <Loader
                                        color={customBlue}
                                        size="2rem"
                                        borderWidth='3px'
                                    />
                                </td>
                            </tr>
                        ) : filteredFeature?.length > 0 ? (
                            filteredFeature.map((item, index) => (
                                <tr key={index} className='border-b border-gray-300'>
                                    <td className='font-quicksand font-semibold text-sm p-4'>{item?.name}</td>
                                    <td>
                                        <div className='flex flex-wrap gap-2 p-4'>
                                            <Permissions
                                                featureName={item?.name}
                                                permissions={item?.permissions || permissionsList}
                                                selectedRole={selectedRole}
                                                setSelectedRole={setSelectedRole}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={5}
                                    className='border-b border-gray-300'
                                >
                                    <NoDataFound
                                        message={'No Feature Found'}
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
