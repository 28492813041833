import axios from "axios";
import { setRecoil } from 'recoil-nexus';
import store from 'store';
import { ACCESS_TOKEN, BASE_URL, ERROR_VARIANT, SOMETHING_WENT_WRONG } from "../constants/constant";
import { isAuthenticatedState } from "../recoil/store";
import showToast from "../utils/toast";


const createAxiosInstance = (baseURL) => {
    const instance = axios.create({ baseURL });

    // Request Interceptor
    instance.interceptors.request.use((request) => {
        const accessToken = store.get(ACCESS_TOKEN);
        if (accessToken) {
            request.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return request;
    });

    // Response Interceptor
    instance.interceptors.response.use(
        (response) => response,
        (error) => {

            if (!navigator.onLine) {
                showToast('You are offline', ERROR_VARIANT);
            }
            else if (error.response?.status === 401) {
                const requestUrl = error?.config?.url;
                const excludedUrls = ['/users/profile', '/signin', '/signup'];
                const shouldShowToast = !excludedUrls.some((url) => requestUrl.includes(url));

                if (shouldShowToast)
                    showToast('Session timed out. Log in', ERROR_VARIANT);

                setRecoil(isAuthenticatedState, false);
                store.remove(ACCESS_TOKEN);
            }
            // else if (error.response?.status >= 400) {
            //     showToast(SOMETHING_WENT_WRONG, ERROR_VARIANT);
            // }
            return Promise.reject(error);
        }
    );

    return instance;
};

export const authInstance = createAxiosInstance(`${BASE_URL}/v1/auth`);
export const roleInstance = createAxiosInstance(`${BASE_URL}/v1`);
export const featureInstance = createAxiosInstance(`${BASE_URL}/v1`);
export const userInstance = createAxiosInstance(`${BASE_URL}/v1`);
export const factInstance = createAxiosInstance(`${BASE_URL}/v1`);
