import React from 'react'
import { appLogo } from '../constants/ImagesAndIcons'
import { CustomButton } from '../components/common/Form'
import { useNavigate } from 'react-router-dom'
import { customBlue } from '../constants/color';

export default function UnauthorizedPage() {
    const navigation = useNavigate();
    return (
        <div className='flex justify-center items-center h-[100vh] bg-white relative'>
            <div className='flex justify-center items-center h-[90vh] w-[90vw] bg-gray-100 rounded-3xl shadow-2xl overflow-hidden'>

                <div className='flex flex-col items-center w-96'>
                    <img src={appLogo} className='h-36 w-36' />
                    <p
                        className='font-quicksand font-semibold text-2xl mt-12 text-center ' >
                        401 Unauthorized
                    </p>

                    <p
                        className='font-quicksand font-semibold text-gray-400 text mt-2 text-center' >
                        We couldn't validate your credentials. Please ask your Administrator to assign role.
                    </p>

                    <div className='mt-5'>
                        <CustomButton
                            text='Back to home'
                            height='2.5rem'
                            width='8.5rem'
                            onClick={() => navigation('/')}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
