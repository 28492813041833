import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

const Pagination = ({ totalPages, pageNumber, setPageNumber, pageSize, setPageSize }) => {
    const renderPageNumbers = () => {
        const totalPagesToShow = 5; // Limit the number of visible page numbers
        const startPage = Math.max(0, pageNumber - Math.floor(totalPagesToShow / 2));
        const endPage = Math.min(totalPages - 1, startPage + totalPagesToShow - 1);

        let pages = [];

        // Add "First" and "..." before the page numbers
        if (startPage > 0) {
            pages.push(
                <button
                    key="first"
                    className="px-3 py-1 bg-gray-200 rounded text-sm font-medium font-quicksand"
                    onClick={() => setPageNumber(0)}
                >
                    Start
                </button>,
                <span key="start-ellipsis" className="px-3 py-1 text-sm font-medium font-quicksand">
                    ...
                </span>
            );
        }

        // Add the actual page numbers
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`px-3 py-1 rounded text-sm font-medium font-quicksand ${pageNumber === i ? 'bg-blue-500 text-white' : 'bg-gray-200 '}`}
                    onClick={() => setPageNumber(i)}
                >
                    {i + 1}
                </button>
            );
        }

        // Add "..." and "Last" after the page numbers
        if (endPage < totalPages - 1) {
            pages.push(
                <span key="end-ellipsis" className="px-3 py-1 text-sm font-medium font-quicksand">
                    ...
                </span>,
                <button
                    key="last"
                    className="px-3 py-1 bg-gray-200 rounded text-sm font-medium font-quicksand"
                    onClick={() => setPageNumber(totalPages - 1)}
                >
                    End
                </button>
            );
        }

        return pages;
    };

    return (

        <div className="flex justify-between items-center">
            <div className="text-sm font-quicksand text-gray-700">
                Page{' '}
                <strong className='text-sm font-quicksand'>
                    {pageNumber + 1} of {totalPages}
                </strong>
            </div>

            <div className="flex space-x-2 items-center">
                <button
                    className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    onClick={() => setPageNumber(pageNumber - 1)}
                    disabled={pageNumber === 0}
                >
                    <Icon
                        icon='iconamoon:arrow-left-2'
                        color='black'
                        fontSize={20}
                    />
                </button>

                {renderPageNumbers()}

                <button
                    className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50 text-sm font-medium font-quicksand"
                    onClick={() => setPageNumber(pageNumber + 1)}
                    disabled={pageNumber >= totalPages - 1}
                >
                    <Icon
                        icon='iconamoon:arrow-right-2'
                        color='black'
                        fontSize={20}
                    />
                </button>
            </div>

            <select
                className="p-1 rounded outline-none text-gray-500 font-semibold font-quicksand"
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    setPageNumber(0);
                }}
            >
                {[5, 10, 20].map((pageSize) => (
                    <option key={pageSize} value={pageSize} >
                        Show {pageSize}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Pagination;
