import React, { useEffect, useState } from 'react'
import RoleDataTable from '../components/dataTables/RoleDataTable'
import { getAllFeaturesApi } from '../rest/FeatureApi';
import { getRoleByNameApi } from '../rest/RoleApi';
import { permissionsList } from '../constants/dummyData';
import Feature from '../components/feature/Feature';

export default function RolePage() {
    const [allFeatures, setAllFeatures] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isFeatureFetching, setIsFeatureFetching] = useState(false);

    function getFeatures(role) {
        const permissionsAndRole = allFeatures.map(feature => {
            const featurePermissions = permissionsList.map(permission => {
                // Check if there's a matching permission in the permissions array
                const permissionKey = `${feature.name}.${permission.permissionCode}`;
                return {
                    ...permission,
                    isGranted: role?.permissions?.includes(permissionKey) ? true : false
                };
            });

            return {
                name: feature.name,
                permissions: featurePermissions
            };
        });

        return permissionsAndRole;
    }

    // Fetch role by name from the API
    const fetchRoleByName = async (roleName) => {
        setIsFeatureFetching(true);
        const result = await getRoleByNameApi(roleName);
        if (result?.status === 200) {
            setSelectedRole(result.data);
            setAllFeatures(() => getFeatures(result.data));
        }
        setIsFeatureFetching(false);
    };

    // Fetch features data from the API
    const fetchFeatures = async () => {
        setIsFeatureFetching(true);
        const result = await getAllFeaturesApi();
        if (result?.status === 200) {
            setAllFeatures(result.data.results);
        }
        setIsFeatureFetching(false);
    };

    useEffect(() => {
        fetchFeatures();
    }, []);

    return (
        <div className='mx-8 flex gap-4 relative'>

            <div className='w-1/2'>
                <RoleDataTable
                    fetchRoleByName={fetchRoleByName}
                    allFeatures={allFeatures}
                />
            </div>

            <div className='w-1/2'>
                <Feature
                    allFeatures={allFeatures}
                    setAllFeatures={setAllFeatures}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    isFeatureFetching={isFeatureFetching}
                />
            </div>
        </div>
    )
}
