import React from 'react'
import { magnifyingGlass } from '../../constants/ImagesAndIcons'

export default function NoDataFound({ message = 'No Data Found' }) {
    return (
        <div className='flex flex-col justify-center items-center py-4'>
            <img src={magnifyingGlass} className='h-20 w-20' />
            <p className="mt-2 text-sm font-quicksand font-bold text-gray-700 text-center">
                {message}
            </p>
        </div>
    )
}
