
// <--------- Url --------->
// export const BASE_URL = 'http://127.0.0.1:8080';
// export const BASE_URL = 'https://api.dev.factaa.com';
export const BASE_URL = 'https://api.factaa.com';
export const FACT_IMAGE_URL = `${BASE_URL}/`;

// <--------- Repair File Url --------->
export const REPAIR_FILE_URL = 'http://77.37.63.64:8104/file/repair';

// <--------- Tokens --------->
export const ACCESS_TOKEN = 'ALLFACTS_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'ALLFACTS_REFRESH_TOKEN';

// <--------- App Theme --------->
export const APP_THEME = 'ALLFACTS_APP_THEME';

export const GOOGLE_CLIENT_ID = '779276582791-o6j3geiuge820jpkt541u2og9lladm2m.apps.googleusercontent.com';

// <--------- Snackbar Variant --------->
export const SUCCESS_VARIANT = 'SUCCESS';
export const WARNING_VARIANT = 'WARNING';
export const ERROR_VARIANT = 'ERROR';
export const NO_CONNECTION_VARIANT = 'NO_CONNECTION';

// <--------- Snackbar Message --------->
export const SOMETHING_WENT_WRONG = 'Oops! Something Went Wrong';

// <--------- Image Info --------->
export const FACT_IMAGE_INFO = {
    maxWidth: 1080,
    ratio: 16 / 9
}

export const PROFILE_IMAGE_INFO = {
    maxWidth: 600,
    ratio: 1 / 1
}


// // <--------- Download/Open App --------->
// export const downloadOrOpenApp = () => {
//     window.location.href = 'https://bma.ifalcon.net/app/Home';
//     setTimeout(() => {
//         const userAgent = navigator.userAgent.toLowerCase();
//         if (userAgent.includes('android')) {
//             window.location.href = 'https://play.google.com/store/apps/details?id=com.example.myapp';
//         } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
//             window.location.href = 'https://apps.apple.com/us/app/your-app-name/id123456789';
//         }
//     }, 100);
// };

// // <--------- Get Current Date --------->
// export function getCurrentDate() {
//     const today = new Date();
//     const year = today.getFullYear();
//     const month = String(today.getMonth() + 1).padStart(2, '0');
//     const day = String(today.getDate()).padStart(2, '0');
//     const currentDate = `${year}-${month}-${day}`;
//     return currentDate;
// }

// export function dateConversion(date) {
//     const utcDate = moment.utc(date);
//     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//     const localDate = utcDate.tz(timeZone);

//     const year = localDate.format('YYYY');
//     const month = localDate.format('MM');
//     const day = localDate.format('DD');
//     const hours = localDate.format('hh');
//     const minutes = localDate.format('mm');
//     const ampm = localDate.format('A');

//     const formattedTime = `${hours}:${minutes} ${ampm}`;
//     const formattedDate1 = `${day}-${month}-${year}`;
//     const formattedDate2 = `${year}-${month}-${day}`;

//     return { formattedDate1, formattedDate2, formattedTime };
// }

// // <--------- Get Formatted Date(2024-05-31 to 31MAY2024) --------->
// export const formatDate = (dateString) => {
//     try {
//         const date = moment(dateString);

//         const dayOfWeek = date.format('dddd');
//         const day = date.format('DD');
//         const month = date.format('MMM').toUpperCase();
//         const year1 = date.format('YYYY');
//         const year2 = date.format('YY');

//         const dateType1 = `${dayOfWeek} ${day}${month}${year1}`;
//         const dateType2 = `${day}${month}${year2}`;

//         return { dateType1, dateType2 };
//     } catch (error) {
//         console.error('Error formatting date:', error);
//         return dateString;
//     }
// };

// // <--------- Get Device Unique Id --------->
// export async function getDeviceId() {
//     const id = await DeviceInfo.getUniqueId();
//     return id;
// }

