import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CustomButton, InputField } from '../common/Form';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function AddFactQuestionForm({ setShowFactDetail, setFactQuestions, isLoading, handleFactSubmission }) {
    const {
        control,
        register,
        watch,
        setError,
        setValue,
        getValues,
        handleSubmit,
        clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues: {
            questions: [
                {
                    question: "",
                    options: {
                        A: { value: "", correct: false },
                        B: { value: "", correct: false },
                        C: { value: "", correct: false },
                        D: { value: "", correct: false },
                    },
                    correctOption: null,
                }
            ]
        }
    });

    const { fields: questionFields, append: appendQuestion, remove: removeQuestion } = useFieldArray({
        control,
        name: "questions",
    });

    function getQuestionNumber(index) {
        return (
            <span className="text-black text-center font-quicksand font-semibold ml-3">
                {`Q${index + 1}.`}
            </span>
        );
    }

    function getOption(optionKey, placeholder, questionIndex) {
        const isSelected = watch(`questions.${questionIndex}.correctOption`) === optionKey;
        const backgroundColor = isSelected ? 'bg-yellow-500' : 'bg-custom-blue';
        return (
            <InputField
                name={`questions[${questionIndex}].options.${optionKey}.value`}
                register={register}
                placeholder={placeholder}
                showError={false}
                errors={errors}
                element={
                    <div
                        onClick={() => handleSelectCorrectOption(questionIndex, optionKey)}
                        className={`h-9 w-9 cursor-pointer ml-[2px] flex justify-center items-center rounded ${backgroundColor}`}>
                        <span className="text-white text-center font-bold text-lg">
                            {optionKey}
                        </span>
                    </div>
                }
            />
        );
    }

    function handleSelectCorrectOption(questionIndex, optionKey) {
        const optionsPath = `questions.${questionIndex}.options`;
        const currentOptions = getValues(optionsPath);

        const updatedOptions = Object.keys(currentOptions).reduce((acc, key) => {
            acc[key] = {
                ...currentOptions[key],
                correct: key === optionKey
            };
            return acc;
        }, {});

        setValue(optionsPath, updatedOptions);
        setValue(`questions.${questionIndex}.correctOption`, optionKey);
    }

    function validateQuestions(data) {
        let isValid = true;

        data.questions.forEach((question, index) => {
            const allFieldsFilled = question.question !== "" &&
                Object.values(question.options).every(option => option.value !== "");

            // Check if a correct option is selected
            const correctOptionSelected = question.correctOption !== null;

            if (!allFieldsFilled && !correctOptionSelected) {
                setError(`questions.${index}`, {
                    type: "manual",
                    message: "Please fill all fields and select a correct option."
                });
                isValid = false;
            } else if (allFieldsFilled && !correctOptionSelected) {
                setError(`questions.${index}`, {
                    type: "manual",
                    message: "Please select a correct option."
                });
                isValid = false;
            } else if (!allFieldsFilled && correctOptionSelected) {
                setError(`questions.${index}`, {
                    type: "manual",
                    message: "Please fill all fields."
                });
                isValid = false;
            }
        });

        return isValid;
    }

    async function onSubmit(data) {
        if (validateQuestions(data)) {
            if (data.questions.length === 1 && data.questions[0].question === "") {
                data = { questions: [] };
            }
            setFactQuestions(prev => data.questions);
            handleFactSubmission(data.questions);
        }
    }

    function customSubmit(e) {
        e.preventDefault();
        clearErrors();
        handleSubmit(onSubmit)();
    }

    return (
        <form onSubmit={customSubmit} className="flex-1 p-6 bg-white">
            <div className="text-black text-center font-quicksand font-bold text-2xl">
                Add Questions
            </div>

            <div className="flex-1 justify-between px-4 mt-4">
                <div>
                    <div className="text-black text-center font-quicksand font-semibold text-sm px-4">
                        Add questions to help users explore and engage with this fact.
                    </div>

                    {questionFields.map((item, index) => (
                        <div key={item.id} className="px-4 mt-4 bg-gray-100 rounded-lg pb-12">
                            <div className="h-12 flex justify-end items-center">
                                {index !== 0 && (
                                    <button
                                        onClick={() => removeQuestion(index)}
                                        className="w-6 h-6 flex justify-center items-center rounded-full bg-white">
                                        <Icon
                                            icon='charm:cross'
                                            color='gray'
                                            fontSize={20}
                                        />
                                    </button>
                                )}
                            </div>

                            <InputField
                                register={register}
                                name={`questions[${index}].question`}
                                placeholder="Enter your question here"
                                errors={errors}
                                showError={false}
                                element={getQuestionNumber(index)}
                            />

                            <div className="px-4 mt-4">
                                <div>{getOption('A', 'First option', index)}</div>
                                <div className="mt-2">{getOption('B', 'Second option', index)}</div>
                                <div className="mt-2">{getOption('C', 'Third option', index)}</div>
                                <div className="mt-2">{getOption('D', 'Fourth option', index)}</div>
                            </div>

                            {errors.questions && errors.questions[index] && (
                                <span className="text-red-500 font-quicksand font-medium text-[11px]">
                                    * {errors.questions[index].message}
                                </span>
                            )}
                        </div>
                    ))}

                    <div className="mt-4 flex justify-end">
                        <div
                            onClick={() =>
                                appendQuestion({
                                    question: "",
                                    options: {
                                        A: { value: "", correct: false },
                                        B: { value: "", correct: false },
                                        C: { value: "", correct: false },
                                        D: { value: "", correct: false },
                                    },
                                    correctOption: null,
                                })
                            }
                            className="h-12 w-12 flex justify-center items-center rounded-full bg-gray-400 cursor-pointer">
                            <Icon
                                icon='ic:round-plus'
                                color='white'
                                fontSize={32}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-6">
                    <div
                        onClick={() => setShowFactDetail(true)}
                        className='w-[6rem] h-[2.5rem] flex justify-center items-center rounded-md bg-gray-200 text-gray-700 font-quicksand font-medium cursor-pointer'>
                        Back
                    </div>
                    <div className='ml-4'>
                        <CustomButton
                            text="Submit"
                            width='6rem'
                            onClick={customSubmit}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}