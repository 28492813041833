import React, { useCallback } from 'react'
import { questionOptionsList } from '../../constants/dummyData'

export default function FactQuestion({ question, questionIndex = 0 }) {
    const getOptionObj = useCallback(((optionName) => questionOptionsList?.find(item => item.optionName === optionName)), []);

    return (
        <div className='bg-blue-300 mx-4 mt-6 rounded-lg p-8'>

            <div
                className='flex flex-col justify-center items-center  px-4 py-6 text-center rounded-xl font-quicksand font-semibold text-gray-600 bg-white min-h-32'>
                <div
                    className='mb-1 font-bold text'>
                    {`Q${questionIndex + 1}.`}
                </div>
                <div className='text'>
                    {question?.question}
                </div>
            </div>

            <div className='mt-8'>
                {
                    Object.entries(question?.options || {}).map(([key, option]) => (
                        <div
                            key={key}
                            className={`flex items-center rounded-lg mt-3 h-12 px-2 cursor-pointer ${option?.correct ? getOptionObj(key)?.optionBackgroundColor : 'bg-white'}`}
                        >
                            <div
                                className={`flex justify-center items-center font-quicksand font-bold h-8 w-8 rounded-lg mr-4 text-lg text-white ${getOptionObj(key)?.optionColor || 'bg-gray-300'}`}
                            >
                                {key.toLowerCase()}
                            </div>
                            <div className='font-quicksand font-medium text text-gray-600'>
                                {option?.value || ''}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
