import React, { useState } from 'react'
import { CustomButton, InputField, Label } from '../common/Form'
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useTokenManager } from '../../hooks/useTokenManager';
import { resendOTPApi, userLoginApi } from '../../rest/AuthApi';
import { ERROR_VARIANT, SOMETHING_WENT_WRONG, SUCCESS_VARIANT } from '../../constants/constant';
import showToast from '../../utils/toast';
import { useNavigate } from 'react-router-dom';

export default function LoginForm() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { saveTokens } = useTokenManager();

    async function handleReSendOtp(email) {
        const result = await resendOTPApi({ email });
        if (result?.status === 200) {
            const param = { email: email };
            navigate('/Verifyotp', { state: param });
        }
        else if (result?.data?.code === 'DEFAULT')
            showToast('Your Email is already Verified');
    };

    // <--------- Form submit function --------->
    async function onSubmit(data) {
        data.email = data.email.toLowerCase();
        setIsLoading(true);
        const result = await userLoginApi(data);
        if (result?.status === 200) {
            await saveTokens(result?.data?.accessToken, result?.data?.refreshToken);
        }
        else if (result?.data?.code === 'DEFAULT')
            showToast('Invalid Credentials', ERROR_VARIANT);
        else if (result?.data?.code === 'EMAIL_NOT_VERIFIED')
            await handleReSendOtp(data.email);
        setIsLoading(false);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex-col justify-center'>
            <div
                className='text-center font-quicksand font-bold text-black text-2xl mt-8'>
                Login
            </div>

            <div className='mt-8'>
                <Label text='Email' />
                <InputField
                    register={register}
                    name={'email'}
                    placeholder={'Enter your email address...'}
                    pattern={{
                        required: "Your email is the key—don’t leave it out!",
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Invalid email*"
                        }
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-4'>
                <Label text='Password' />
                <InputField
                    style=''
                    type='password'
                    register={register}
                    name={'password'}
                    secureTextEntry={true}
                    placeholder={'Create a secure password...'}
                    pattern={{
                        required: "Your password is required"
                    }}
                    errors={errors}
                />
            </div>

            <div className='mt-6'>
                <CustomButton
                    text='Login'
                    isLoading={isLoading}
                    height='2.5rem'
                />
            </div>

            <div
                // onClick={signIn} 
                className='flex justify-center items-center bg-white border-[1px] border-gray-200 rounded-md h-10 mt-4'>
                <Icon
                    icon='flat-color-icons:google'
                    fontSize={26}
                />
                <span
                    className='text-black text-center text-sm font-quicksand font-medium ml-3'>
                    Login with Google
                </span>
            </div>


            <div className='mt-6'>
                <div
                    // style={{ fontSize: scaleFont(13) }}
                    className='text-center font-quicksand font-medium text-black text-sm'>
                    Forgot
                    <span
                        onClick={() => navigate('/forgotpassword')}
                        className='text-custom-blue font-quicksand font-bold cursor-pointer'>
                        {` password `}
                    </span>
                    ?
                </div>
                <div
                    className='text-center font-quicksand font-medium text-black text-sm mt-4'>
                    Don’t have an account?
                    <span
                        onClick={() => navigate('/signup')}
                        className='text-custom-blue font-quicksand font-bold cursor-pointer'>
                        {` Sign up`}
                    </span>
                </div>
            </div>
        </form>
    )
}
